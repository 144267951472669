import * as React from 'react';
import { CSSProperties, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { topNavBarHeight } from "./constants";
import { logOut, useDispatch } from "../../state";
import { Routes } from '../../util/constants'
import { useNavigate } from "react-router-dom";


import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Badge from '@mui/material/Badge';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LanguageIcon from '@mui/icons-material/Language';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import AddCardIcon from '@mui/icons-material/AddCard';
import ListItemIcon from '@mui/material/ListItemIcon';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import OrderPlusIcon from '../Icons/OrderPlusIcon';
import InvoicePlusIcon from '../Icons/InvoicePlusIcon';
import EstimatePlusIcon from '../Icons/EstimatePlusIcon';
import PackingSlipPlusIcon from '../Icons/PackingSlipPlusIcon';
import VendorSlipIcon from '../Icons/VendorSlipIcon';
import MainAppLogoIcon from '../Icons/MainAppLogoIcon';
import { openCustomerPopupModal } from '../../state/customerPopupModalSlice';
import { openVendorPopupModal } from '../../state/vendorPopupModalSlice';



export default function TopNavBar({
  setLoading,
}: {
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>
}): JSX.Element {
  const theme = useTheme();
  const { t } = useTranslation([], { keyPrefix: "team.PageLayout.TB" })  // TODO in the future we will be changing language in this component. Add 'i18n' to hook so you can set language. Should look like 'const { t, i18n } = useTranslation();'. Set language like this: 'i18n.changeLanguage('de')'. 
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const iconStyle: CSSProperties = {
    fontSize: '24px',
    color: theme.palette.primary.main,
  };

  const MenuItemHeight = 44

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [anchorElNew, setAnchorElNew] = useState<null | HTMLElement>(null);

  const [currentLanguage] = useState<string>("English");


  const handleOpenNewMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNew(event.currentTarget);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNewMenu = () => {
    setAnchorElNew(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleCreateCustomer = () => {
    dispatch(openCustomerPopupModal())
    handleCloseNewMenu()
  };

  const handleCreateVendor = () => {
    dispatch(openVendorPopupModal())
    handleCloseNewMenu()
  };


  const handleLogOut = async () => {
    try {
      setLoading?.(true)
      await dispatch(logOut());
      setLoading?.(false)
      navigate(Routes.LOGIN)
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <AppBar elevation={4} position="static" sx={{ height: topNavBarHeight }}>
      <Container maxWidth={false} disableGutters sx={{ paddingLeft: 0, paddingRight: "24px" }}>
        <Toolbar disableGutters>
          <Box sx={{ display: "flex", mx: 1 }}>
            <MainAppLogoIcon style={{ width: "64px", height: "64px" }} />
          </Box>
          <Box sx={{ ml: "auto" }} >
            {/* Language */}
            <Button variant="outlined" size="small" sx={{ mr: 2 }} startIcon={<LanguageIcon />}>
              {currentLanguage}
            </Button>
            {/* New Button */}
            <Button
              variant="contained"
              endIcon={<ArrowDropDownIcon />}
              sx={{ mr: 2 }}
              size="small"
              onClick={handleOpenNewMenu}
            >
              {t("New")}
            </Button>
            <Menu
              elevation={4}
              MenuListProps={{ sx: { paddingTop: 0, paddingBottom: 0 } }}
              sx={{ mt: "45px" }}

              id="new-appbar"
              anchorEl={anchorElNew}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElNew)}
              onClose={handleCloseNewMenu}
            >
              <MenuItem divider key={"Customer"} onClick={handleCreateCustomer} sx={{ paddingY: 0, height: MenuItemHeight }}>
                <ListItemIcon>
                  <PersonAddIcon color="primary" />
                </ListItemIcon>
                <Typography textAlign="center" color="primary"> {t("Customer")}</Typography>
              </MenuItem>
              <MenuItem divider key={"Vendor"} onClick={handleCreateVendor} sx={{ paddingY: 0, height: MenuItemHeight }}>
                <ListItemIcon>
                  <VendorSlipIcon style={iconStyle} />
                </ListItemIcon>
                <Typography textAlign="center" color="primary">{t("Vendor")}</Typography>
              </MenuItem>
              <MenuItem divider key={"Order"} onClick={handleCloseNewMenu} sx={{ paddingY: 0, height: MenuItemHeight }}>
                <ListItemIcon>
                  <OrderPlusIcon style={iconStyle} />
                </ListItemIcon>
                <Typography textAlign="center" color="primary">{t("Order")}</Typography>
              </MenuItem>
              <MenuItem divider key={"Invoice"} onClick={handleCloseNewMenu} sx={{ paddingY: 0, height: MenuItemHeight }}>
                <ListItemIcon>
                  <InvoicePlusIcon style={iconStyle} />
                </ListItemIcon>
                <Typography textAlign="center" color="primary">{t("Invoice")}</Typography>
              </MenuItem>
              <MenuItem divider key={"Packing Slip"} onClick={handleCloseNewMenu} sx={{ paddingY: 0, height: MenuItemHeight }}>
                <ListItemIcon>
                  <PackingSlipPlusIcon style={iconStyle} />
                </ListItemIcon>
                <Typography textAlign="center" color="primary">{t("Packing Slip")}</Typography>
              </MenuItem>
              <MenuItem divider key={"Payment"} onClick={handleCloseNewMenu} sx={{ paddingY: 0, height: MenuItemHeight }}>
                <ListItemIcon>
                  <AddCardIcon color="primary" />
                </ListItemIcon>
                <Typography textAlign="center" color="primary">{t("Payment")}</Typography>
              </MenuItem>
              <MenuItem key={"Estimate"} onClick={handleCloseNewMenu} sx={{ paddingY: 0, height: MenuItemHeight }}>
                <ListItemIcon>
                  <EstimatePlusIcon style={iconStyle} />
                </ListItemIcon>
                <Typography textAlign="center" color="primary">{t("Estimate")}</Typography>
              </MenuItem>
            </Menu>
            {/* New Messages Icon Button */}
            <IconButton
              sx={{ mr: 2 }}
              color="primary"
              size="small"
            >
              <Badge variant="dot" color="error">
                <NotificationsNoneIcon />
              </Badge>
            </IconButton>
            {/* Avatar */}
            <Tooltip title="Open settings">
              <IconButton size="small" onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                {/* TODO  Avatar Picture will be here */}
                <Avatar sx={{ width: 32, height: 32 }} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              MenuListProps={{ sx: { paddingTop: 0, paddingBottom: 0 } }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem divider key={"Account"} onClick={handleCloseUserMenu} sx={{ paddingY: 0, height: MenuItemHeight }}>
                <ListItemIcon>
                  <AccountCircleIcon color="primary" />
                </ListItemIcon>
                <Typography textAlign="center" color="primary">{t("Account")}</Typography>
              </MenuItem>
              <MenuItem divider key={"Team"} onClick={handleCloseUserMenu} sx={{ paddingY: 0, height: MenuItemHeight }}>
                <ListItemIcon>
                  <PeopleAltOutlinedIcon color="primary" />
                </ListItemIcon>
                <Typography textAlign="center" color="primary">{t("Team")}</Typography>
              </MenuItem>
              <MenuItem divider key={"Sign Out"} onClick={handleLogOut} sx={{ paddingY: 0, height: MenuItemHeight }}>
                <ListItemIcon>
                  <LogoutOutlinedIcon color="primary" />
                </ListItemIcon>
                <Typography textAlign="center" color="primary">{t("Sign Out")}</Typography>
              </MenuItem>
              {/* TODO Verson of app should be dynamic. */}
              <Typography textAlign="center" fontSize="10px" sx={{ padding: "2px" }}>version 2024.05.123</Typography>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

import { createTRPCProxyClient, httpLink, HTTPHeaders, Operation } from '@trpc/client';
import type { AppRouter } from "orderflow-lambdas"
import { sign, Credentials, Request } from "aws4"


let host = ''
if(process.env.REACT_APP_ENVIRONMENT === 'production') host = 'api.orderflowteam.com'
if(process.env.REACT_APP_ENVIRONMENT === 'qa') host = 'api.qa.orderflowteam.com'
if(process.env.REACT_APP_ENVIRONMENT === 'development') host = 'api.dev.orderflowteam.com'
if(process.env.REACT_APP_ENVIRONMENT === 'local') host = 'http://127.0.0.1:3010'



class CredentialHelper {
    credentials: Credentials | undefined = undefined;
    userId: string | undefined = undefined;
    teamId: string | undefined = undefined;

    getCredentials(): Credentials | undefined {
        return this.credentials
    }
    getUserId(): string | undefined {
        return this.userId
    }
    getTeamId(): string | undefined {
        return this.teamId
    }
    setCredentials(creds: Credentials | undefined, _userId: string | undefined) {
        this.credentials = creds
        this.userId = _userId
    }
    
    setUserId(_userId: string | undefined) {
        this.userId = _userId
    }
    setTeamId(_teamId: string | undefined) {
        this.teamId = _teamId
    }
}

export const CredentialManager = new CredentialHelper()

export function getHeaders(op: Operation): HTTPHeaders {
    const methods = {
        query: 'GET',
        mutation: "POST",
        subscription: "POST"
    }
    const queryParams = op.type === "query" && op.input ? `?input=${encodeURIComponent(JSON.stringify(op.input || ''))}` : ''
    let path = `/${op.path}${queryParams}`
    if (process.env.REACT_APP_ENVIRONMENT !== 'local') {
       path = `/${process.env.REACT_APP_ENVIRONMENT}/${op.path}${queryParams}`
    }
    const body = op.type === "mutation" && op.input ? `${JSON.stringify(op.input)}` : undefined

    const opts: Request = {
        method: methods[op.type],
        host,
        path,
        body,
        service: 'execute-api',
        region: 'us-east-1',
        headers: {
            'content-type': 'application/json',
        },
    }
    // console.log("OPTS", opts)
    // console.log("creds", CredentialManager.getCredentials())
    const request = sign(opts, CredentialManager.getCredentials())
    if(!request.headers) return {}
    return request.headers as HTTPHeaders
}


export const client = createTRPCProxyClient<AppRouter>({
    links: [
        httpLink({
        url: process.env.REACT_APP_ENVIRONMENT !== 'local' ? `https://${host}/${process.env.REACT_APP_ENVIRONMENT}` : host,
        headers(opts) {
            if(process.env.REACT_APP_ENVIRONMENT === 'local') return {
                "user-id": CredentialManager.getUserId(),
                "team-id": CredentialManager.getTeamId(),
            }
            return {
                ...getHeaders(opts.op),
                "team-id": CredentialManager.getTeamId(),
            }
        },
        }),
    ],
});

export default client
import "immer"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import type { Item, ItemDeleteInput } from "orderflow-lambdas"
import ApiClient from "../../util/api-client"

export interface ItemPopupModalState {
  openItemPopupModal: boolean
  loading: boolean
}

// Initialize state
const initialState: ItemPopupModalState = {
  openItemPopupModal: false,
  loading: false
}

export const itemPopupModalSlice = createSlice({
  name: "itemPopupModal",
  initialState,
  reducers: {
    openItemPopupModal: (state) => ({
      ...state,
      openItemPopupModal: true,
    }),
    closeModal: () => ({
      ...initialState
    }),
    setLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      loading: action.payload,
    }),
  },
})

export const {
  openItemPopupModal,
  closeModal,
  setLoading
} = itemPopupModalSlice.actions
export default itemPopupModalSlice.reducer

// Thunks
export const deleteItem = (deleteInput: ItemDeleteInput) => async (dispatch: Dispatch): Promise<void> => {
  try {
    dispatch(setLoading(true))
    await ApiClient.deleteItem.mutate(deleteInput)
  } finally {
    dispatch(setLoading(false))
  }
}

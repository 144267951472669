import { useEffect, useState } from "react";
import { Box, Button, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, TextField, FormControl, InputLabel, FilledInput, FormHelperText } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
// TODO update input when api is ready
import type { SystemNumbers, SystemNumbersUpdateInput } from "../../../../../../packages/orderflow-lambdas/src/shared/api/models/systemNumbers";
import { useTranslation } from "react-i18next";
import { buttonWidth, textFieldProps } from "../constants/form";
import { pushToast, useDispatch, useSelector } from "../../../state";
import { closeModal, setLoading } from "../../../state/systemNumbersModalSlice";
import { getFormikField } from "../../../util/fornik-helpers";
import { systemNumbersSchema } from "./schemas/systemNumbersSchema";
import { TRPCClientError } from "@trpc/client";
import { serverError } from "../constants/errors";
import { client } from "../../../util";

const columnPadding = "0 2px"


const initialValues: SystemNumbers = {
    SystemNumberId: "",
    nextOrderNo: 0,
    nextPackingSlipNo: 0,
    nextEstimateNo: 0,
    TeamId: "",
    createdBy: "",
    updatedAt: 0,
    createdAt: 0
};

export default function SystemNumbersModal() {
    const { t: tGlobal } = useTranslation([], { keyPrefix: "team.Global.Buttons" })

    const dispatch = useDispatch();

    const { openSystemNumbersModal, loading } = useSelector(({ systemNumbersModalSlice }) => systemNumbersModalSlice);

    const [minNextOrderNo, setMinNextOrderNo] = useState(0);

    const resetState = () => {
        formik.resetForm()
    };

    const handleClose = () => {
        dispatch(closeModal());
        resetState();
    };

    const handleUpdateSystemNumbers = async (payload: SystemNumbers) => {
        dispatch(setLoading(true))
        try {
            // TODO update when api is ready
        } catch (error) {
            if (error instanceof TRPCClientError) {
                dispatch(pushToast({ message: error.message, type: "error" }));
            } else {
                dispatch(pushToast({ message: serverError, type: "error" }));
            }
        }
        dispatch(setLoading(false))
    }

    // Formik initialization
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: systemNumbersSchema,
        onSubmit: handleUpdateSystemNumbers,
    });


    useEffect(() => {
        const fetchData = async () => {
            dispatch(setLoading(true))
            try {
                // TODO connect api when ready
                // const resp = await client.systemNumbers.query();
                // setSystemNumbers(resp.results)
                // setMinNextOrderNo()
            } catch (error) {
                if (error instanceof TRPCClientError) {
                    dispatch(pushToast({ message: error.message, type: "error" }));
                } else {
                    dispatch(pushToast({ message: serverError, type: "error" }));
                }
                handleClose()
            }
            dispatch(setLoading(false))
        };
        fetchData();
    }, []);

    return (
        <Dialog
            open={openSystemNumbersModal}
            onClose={(event,reason)=>{
                if (reason && reason === "backdropClick") 
                    return;
                handleClose()
            }}
            maxWidth="lg"
            scroll="paper"
            PaperProps={{ className: "w-full" }}
        >
            <DialogTitle sx={{ m: 0, p: 1 }}>{"System Numbers"}</DialogTitle  >
            <IconButton disabled={loading} color="primary" sx={{ position: "absolute", right: 4, top: 4 }} onClick={handleClose}>
                <CloseIcon />
            </IconButton>
            <DialogContent dividers sx={{ p: 3 }} >
                {loading ? (
                    <Box height="400px" display="flex" justifyContent="center" alignItems="center">
                        <CircularProgress size={80} />
                    </Box>
                ) : (
                    <>
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <div className="grid w-full grid-flow-col grid-rows-1 gap-4 py-20 webkit-box">
                                <div className="flex justify-center">
                                    <FormControl variant="filled" {...textFieldProps} error={Boolean(getFormikField(formik, "nextOrderNo").error)}>
                                        <InputLabel>{"Next Order No."}</InputLabel>
                                        <FilledInput
                                            name="nextOrderNo"
                                            type="number"
                                            value={getFormikField(formik, "nextOrderNo").value}
                                            onChange={formik.handleChange}
                                        />
                                        <FormHelperText
                                            sx={{ color: getFormikField(formik, "nextOrderNo").error ? 'error.main' : 'green' }}
                                        >
                                            {getFormikField(formik, "nextOrderNo").error || `You must enter a value higher than ${minNextOrderNo}`}
                                        </FormHelperText>
                                    </FormControl>
                                </div>
                                <div className="flex justify-center">
                                    <TextField variant="filled" {...textFieldProps} label={"Next Packing Slip No."} name="nextPackingSlipNo" type="number"
                                        error={Boolean(getFormikField(formik, "nextPackingSlipNo").error)}
                                        helperText={getFormikField(formik, "nextPackingSlipNo").error}
                                        value={getFormikField(formik, "nextPackingSlipNo").value} onChange={formik.handleChange}
                                    />
                                </div>
                                <div className="flex justify-center">
                                    <TextField variant="filled" {...textFieldProps} label={"Next Estimate No."} name="nextEstimateNo" type="number"
                                        error={Boolean(getFormikField(formik, "nextEstimateNo").error)}
                                        helperText={getFormikField(formik, "nextEstimateNo").error}
                                        value={getFormikField(formik, "nextEstimateNo").value} onChange={formik.handleChange}
                                    />
                                </div>
                            </div>
                        </Box >
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Box width="100%" sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Button disabled={loading} variant="contained" color="primary" sx={{ width: buttonWidth }} onClick={() => {
                        formik.submitForm()
                    }}>{loading ? <CircularProgress size={20} /> : tGlobal("Save")}</Button>
                    <Button disabled={loading} variant="outlined" color="primary" sx={{ width: buttonWidth }} onClick={handleClose}>{tGlobal("Cancel")}</Button>
                </Box>
            </DialogActions>
        </Dialog >
    );
}

/* eslint-disable @typescript-eslint/no-unused-vars */
'use client'
import { Button, CircularProgress, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useState } from "react";
import { AuthError, ResendSignUpCodeInput, SignUpOutput } from "aws-amplify/auth";
import { useNavigate } from "react-router-dom";
import { pushToast, register, resendConfirmation, useDispatch } from "../../state";
import { Routes } from "../../util";
import styles from "./index.module.css"

const passwordExceptionMessage = "Your password must have at least 6 characters, one uppercase letter, one lowercase letter, one number, and one special character."

export default function RegisterTab(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingResend, setLoadingResend] = useState(false);
  const [step, setStep] = useState<SignUpOutput["nextStep"]["signUpStep"] | "">("");

  const handleRegistration = async () => {
    try {
      setLoading(true)
      const {isSignUpComplete, nextStep} = await dispatch(register(email, password));
      if(!isSignUpComplete){
        setStep(nextStep.signUpStep)
        setLoading(true)
        }
    } catch (error: unknown) {
        console.error(error)
        let message = "An error occurred."
        
        if(error instanceof AuthError) {
          switch (true) {
            // handle invalid regex password exception
            case error.message.includes("Value at 'password' failed to satisfy constraint"):
              message = passwordExceptionMessage.toLocaleUpperCase()
              break;
            default:
              message = error.message.toLocaleUpperCase()
              break;
          }
        }

        dispatch(pushToast({
          message,
          type: "error",
          duration: 13000
        }))
    } finally {
      setLoading(false)
    }
  };

  const resend = async () => {
    try {
      setLoadingResend(true)
      const input: ResendSignUpCodeInput = {
        username: email,
      }
      await dispatch(resendConfirmation(input));
      dispatch(pushToast({
        message: "Confirmation code resent.", 
        type: "success"
      }))
    } catch (error: unknown | AuthError) {
      let message = "An error occurred."
      if(error instanceof AuthError) message = error.message.toLocaleUpperCase()
      
      dispatch(pushToast({
        message, 
        type: "error"
      }))
        console.error(error)
        alert(error)
        dispatch(pushToast({
          message,
          type: "error"
        }))
    } finally {
      setLoadingResend(false)
    }
};


  if (step === "CONFIRM_SIGN_UP") {
    return (<>
      <img 
      src="/email-sent.png"
      alt="email-sent"
      />
      <Typography>
       We’ve sent you an email that contains a verification link. 
      </Typography>
      <div className={styles.buttons}>
      <Button
        variant="contained"
        startIcon={<NavigateNextIcon />}
        onClick={() => {
        const encodedEmail = encodeURIComponent(email)
        navigate(`${Routes.SIGNUP_CONFIRM}?email=${encodedEmail}`)
      }}>
        Confirm
      </Button>
      <Button
        variant="outlined"
        startIcon={<NavigateNextIcon />}
        disabled={loadingResend}
        onClick={resend}>
        {loadingResend ? <CircularProgress color="inherit" size={20}/> : "Resend"}
      </Button>
      </div>
    </>)
  }

  return (
      <>
          <Typography>
            Register Your Account
          </Typography>
          <TextField 
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              variant="standard"
              InputProps={{
                startAdornment: <InputAdornment position="start"><EmailIcon color="primary"/></InputAdornment>
              }}
              />
          <TextField 
              label="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
              variant="standard"
              type={showPassword ? "text" : "password"}
              InputProps={{
                startAdornment: <InputAdornment position="start"><LockIcon color="primary"/></InputAdornment>,
                endAdornment: <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <VisibilityOffIcon /> :<VisibilityIcon />}
                    </IconButton>
                  </InputAdornment>
              }}
              />
          <TextField 
              label="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              fullWidth
              variant="standard"
              type={showPassword ? "text" : "password"}
              InputProps={{
                startAdornment: <InputAdornment position="start"><LockIcon color="primary"/></InputAdornment>,
                endAdornment: <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </InputAdornment>
              }}
              />
          <Button
            disabled={loading || !email || !password || password !== confirmPassword}
            fullWidth
            className={styles.registerButton}
            variant="contained"
            onClick={handleRegistration}>
              { loading ? <CircularProgress size={20}/> : "Register"}
          </Button>
        </>
  );
}

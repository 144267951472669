import CreateCustomerPopupModal from "./customers/createPopup"
import CreateEditCustomerModal from "./customers/createAndEdit"
import CustomerTermsModal from "./customerTerms"
import SettingModal from "./settings"
import DeleteCustomerModal from "./customers/delete"
import VendorTermsModal from "./vendorTerms"
import AltShipMethodsModal from "./altShipMethods"
import ShipMethodsModal from "./shipMethods"
import SystemNumbersModal from "./systemNumbers"
import CreateVendorPopupModal from "./vendors/createPopup"
import CreateEditVendorModal from "./vendors/createAndEdit"
import DeleteVendorModal from "./vendors/delete"

/**
 * Modals
 * This should be the single mounting point for all modals. All modals should have a redux slice.
 * The purpose is so that all modals are mounted only once and can be opended from anywhere in the app.
 */
export const Modals = () => {
    return (
        <>
            <CreateCustomerPopupModal />
            <CreateEditCustomerModal />
            <CustomerTermsModal />
            <DeleteCustomerModal />
            <CreateVendorPopupModal />
            <CreateEditVendorModal />
            <VendorTermsModal />
            <DeleteVendorModal />
            <AltShipMethodsModal/>
            <ShipMethodsModal/>
            <SystemNumbersModal/>
            <SettingModal />
        </>
    )
}

export default Modals
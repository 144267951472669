/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, CircularProgress, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useState } from "react";
import { AuthError, ResendSignUpCodeInput, ResetPasswordOutput, SignUpOutput } from "aws-amplify/auth";
import { useNavigate } from "react-router-dom";
import { forgotPasswordChallenge, pushToast, register, resendConfirmation, useDispatch } from "../../state";
import { Routes } from "../../util";
import styles from "./index.module.css"
import emailSent from "../../images/email-sent.png"

export default function RegisterTab(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState<ResetPasswordOutput["nextStep"]["resetPasswordStep"] | "">("");

  const sendChallenge = async () => {
    try {
      setLoading(true)
      setErrorMessage("")
      const {nextStep, isPasswordReset} = await dispatch(forgotPasswordChallenge({username: email}));
      if(!isPasswordReset){
        setStep(nextStep.resetPasswordStep)
      }
    } catch (error: unknown) {
        console.error(error)
        if(error instanceof AuthError) {
          if(error.name === "UserNotFoundException") {
            setErrorMessage("User not found.")
          }
        }
    } finally {
      setLoading(false)
    }
  };


  if (step === "CONFIRM_RESET_PASSWORD_WITH_CODE") {
    return (
      <>
        <img 
          src={emailSent}
          alt="email-sent"
          // width={emailSent.width}
          // height={emailSent.height}
        />
        <Typography align="center">
          An email has been sent to you with a confirmation code with further instructions.
        </Typography>
        <div className={styles.buttons}>
        <Button
          variant="contained"
          startIcon={<NavigateNextIcon />}
          disabled={loading || !email}
          onClick={() => navigate(`${Routes.FORGOT_PASSWORD_RESET}?email=${encodeURIComponent(email)}`)}
          >
          Confirm
        </Button>
      </div>
      </>
    )
  }

  return (
    <>
      <img
      src={emailSent}
      alt="email-sent" 
      //width={emailSent.width} height={emailSent.height}
      />
      <Typography>
        You will receive instructions for resetting your password.
      </Typography>
      <TextField 
        label="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        error={!!errorMessage}
        helperText={errorMessage}
        fullWidth
        variant="outlined"
        InputProps={{
          startAdornment: <InputAdornment position="start"><EmailIcon color="primary" /></InputAdornment>
        }}
      />
      <div className={styles.buttons}>
        <Button
          variant="contained"
          startIcon={<NavigateNextIcon />}
          disabled={loading || !email}
          onClick={sendChallenge}>
          Send
        </Button>
      </div>
    </>
  )
}

import { useEffect } from "react";
import { pushToast, useDispatch, useSelector, logOut } from "../state";
import { fetchTeam } from "../state/userSlice";
import { CircularProgress, Stack } from "@mui/material"
import { AuthError } from "aws-amplify/auth";
import { Routes, Toast, CredentialManager } from "../util";
import {useNavigate} from "react-router-dom"
import { TRPCClientError } from "@trpc/client";


export default function TeamProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const { user, team, session } = useSelector(({ userSlice }) => userSlice)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const getTeam = async () => {
    if (user === "init" || user === "loading" || user instanceof AuthError) return
    if (!session) return
    if (team !== "init") return
    CredentialManager.setCredentials({
      accessKeyId: session.credentials?.accessKeyId,
      secretAccessKey: session.credentials?.secretAccessKey,
      sessionToken: session.credentials?.sessionToken,
  }, user.userId)
    try {
      const teamFecthed = await dispatch(fetchTeam())
      if (teamFecthed) {
        CredentialManager.setTeamId(teamFecthed.TeamId)
      }
    } catch (error: unknown) {
      const toast: Toast = {
        type: "error",
        message: "Error fetching team.",
      }
      if (error instanceof TRPCClientError) {
        if(error.data?.code === "NOT_FOUND") toast.additionalInfo = "Unable to find a team for your user. You will be logged out of OrderfFlow"
      }
      dispatch(pushToast(toast))
      await dispatch(logOut())
      navigate(Routes.LOGIN)
    }
  }

  useEffect(() => {
    getTeam()
  }, [user, team, session])

  if (team === "init" || team === "loading") {
    return (
      <Stack direction="column" justifyContent="center" alignItems="center" height="100%">
        <CircularProgress size={100} />
      </Stack>
    )
  }
  return <>{children}</>
}

'use client'

import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { Routes, publicRoutes } from "../../util";
import { getUser, useDispatch, useSelector } from "../../state";
import { AuthError } from "aws-amplify/auth";

export const useAuth = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()
    const user = useSelector(({userSlice}) => userSlice.user)
    
    const checkSession = async () => {
      try{
        // if(user !== "init" && user !== "loading" && !(user instanceof AuthError)) return
        await dispatch(getUser())
      } catch (error) {
        console.error("Unable to get user", error)
      }
    } 
  
    useEffect(() => {
      checkSession()
    }, [location.pathname])

    useEffect(() => {
        if(user === "init" || user === "loading") return
        if(user instanceof AuthError && !publicRoutes.includes(location.pathname)) {
            const fullPath = `${Routes.LOGIN}?redirect=${location.pathname}`
            navigate(fullPath)
            return
        }
        if(!(user instanceof AuthError) && location.pathname === Routes.LOGIN) {
            navigate(Routes.HOME)
            return
        }
    }, [user])

    return user
}
export default useAuth
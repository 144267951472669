
import { useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import LeftNavBar from "../components/NavBars/LeftNavBar";
import TopNavBar from "../components/NavBars/TopNavBar";
import Modals from "../components/modals";


export default function NavBarsLayout({
    children,
}: {
    children: React.ReactNode;
}): JSX.Element {
    const [loading, setLoading] = useState(false);
    return (
        <>
            {loading ? (
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%", }}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <TopNavBar setLoading={setLoading} />
                    {/* We render childern inside LeftNavBar Because it splits the screen on 2 sides */}
                    <LeftNavBar children={children} />
                    <Modals />
                </>
            )}

        </>
    );
}

/* eslint-disable @typescript-eslint/no-unused-vars */
import { configureStore } from '@reduxjs/toolkit'
import {
    useStore as reduxStore,
    useDispatch as reduxDispatch,
    useSelector as reduxSelector,
  } from "react-redux"

import userSlice from "./userSlice"
import toastsSlice from "./toastSlice"
// Customer
import customerModalSlice from "./customerModalSlice"
import customerPopupModalSlice from "./customerPopupModalSlice"
import customerTermsModalSlice from "./customerTermsModalSlice"
import customerBrowseSlice from "./customerBrowseSlice"
// Vendor
import vendorModalSlice from "./vendorModalSlice"
import vendorPopupModalSlice from "./vendorPopupModalSlice"
import vendorTermsModalSlice from "./vendorTermsModalSlice"
import vendorBrowseSlice from "./vendorBrowseSlice"
//Item
import itemBrowseSlice from "./itemBrowseSlice"

import altShipMethodsModalSlice from "./altShipMethodsModalSlice"
import shipMethodsModalSlice from "./shipMethodsModalSlice"
import systemNumbersModalSlice from "./systemNumbersModalSlice"
import settingsModalSlice from "./settingsModalSlice"

export const store = configureStore({
    reducer: {
        userSlice,
        toastsSlice,
        customerModalSlice,
        customerPopupModalSlice,
        customerTermsModalSlice,
        customerBrowseSlice,
        vendorModalSlice,
        vendorPopupModalSlice,
        vendorTermsModalSlice,
        vendorBrowseSlice,
        altShipMethodsModalSlice,
        shipMethodsModalSlice,
        systemNumbersModalSlice,
        settingsModalSlice,
        itemBrowseSlice
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    }),
  })

// Infer the type of makeStore
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store['getState']>
export type AppDispatch = typeof store['dispatch']


  
  // Use throughout your app instead of plain `useDispatch` and `useSelector`
  export const useDispatch = reduxDispatch.withTypes<AppDispatch>()
  export const useSelector = reduxSelector.withTypes<RootState>()
  
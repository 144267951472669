import LogIn from "./pages/login"
import ForgotPassword from "./pages/forgot-password"
import ResetPassword from "./pages/forgot-password/reset-password"
import AccountConfirmation from "./pages/signup-confirm/page";

import {
  RouteObject,
  createBrowserRouter,
  RouterProvider,
  Outlet,
  Navigate
} from "react-router-dom";

// DashBoard
import DashBoard from "./pages/dashboard"

// Customers
import Customers from "./pages/customers"

// Layouts
import UserIsAuthenticatedGuard from "./components/AuthProvider"
import NavBarsLayout from "./layouts/NavBarsLayout"
import TeamProvider from "./components/TeamProvider";
import Vendors from "./pages/vendors";
import Items from "./pages/items";

const routes: RouteObject[] = [
  {
    errorElement: <div>404 Not Found</div>,
    children: [
      // REDIRECT ROUTES
      {
        index: true,
        element: <Navigate to="login" replace={true} />,
      },

      // UNPROTECTED ROUTES
      {
        path: "/login",
        element: <LogIn />,
      },
      {
        path: "/forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "/forgot-password/reset-password",
        element: <ResetPassword />,
      },
      {
        path: "/signup-confirm",
        element: <AccountConfirmation />,
      },
      // PROTECTED ROUTES
      {
        element: (
          <UserIsAuthenticatedGuard>
              <Outlet />
          </UserIsAuthenticatedGuard>
        ),
        children: [
          {
            path: "/:team",
            element: (
              // <CustomerExistsGuard>
              //   <CurrentUserLoaderGuard>
              <TeamProvider>
                <NavBarsLayout>
                  <Outlet />
                </NavBarsLayout>
              </TeamProvider>
              //   </CurrentUserLoaderGuard>
              // </CustomerExistsGuard>
            ),
            children: [
              {
                index: true,
                element: <Navigate to="dashboard" replace={true} />,
              },
              {
                path: "/:team/dashboard",
                element: <DashBoard />,
              },
              {
                path: "/:team/customers",
                element: <Customers />,
              },
              {
                path: "/:team/vendors",
                element: <Vendors />,
              },
              {
                path: "/:team/items",
                element: <Items />,
              },
            ]
          }
        ]
      }
    ]
  }
]

const router = createBrowserRouter(routes)

export default function Router() {
  return <RouterProvider router={router} />
}
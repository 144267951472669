import { Box, Typography, TextField, Input, FormHelperText, FormControl, FormControlLabel, Checkbox } from "@mui/material";
import type { OrderflowSettings } from "orderflow-lambdas";
import { textFieldProps, wideTextFieldProps } from "../constants/form";
import { FormikProps } from "formik";
import { getFormikField } from "../../../util/fornik-helpers";
import { useTranslation } from "react-i18next";

export default function PurchaseOrdersTab({
    settingsFormik,
}: {
    settingsFormik: FormikProps<OrderflowSettings>;
}): JSX.Element {
    const { t: tGC } = useTranslation([], { keyPrefix: "team.Global.Common" })
    const { t: tMSP } = useTranslation([], { keyPrefix: "team.Modals.Settings.PurchaseOrdersTab" })
    
    return (
        <>
            <div className="grid grid-flow-col gap-4 grid-rows-7 webkit-box">
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={tMSP("Ship to")} name="purchaseOrders.shipTo"
                        error={Boolean(getFormikField(settingsFormik, "purchaseOrders.shipTo").error)}
                        helperText={getFormikField(settingsFormik, "purchaseOrders.shipTo").error}
                        value={getFormikField(settingsFormik, "purchaseOrders.shipTo").value} onChange={settingsFormik.handleChange}
                    />
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={tMSP("Approximate Ship Date")} name="purchaseOrders.approximateShipDate"
                        error={Boolean(getFormikField(settingsFormik, "purchaseOrders.approximateShipDate").error)}
                        helperText={getFormikField(settingsFormik, "purchaseOrders.approximateShipDate").error}
                        value={getFormikField(settingsFormik, "purchaseOrders.approximateShipDate").value} onChange={settingsFormik.handleChange}
                    />
                </div>
                <div className="flex justify-center">
                    <Box  {...textFieldProps}>
                        <Box display="flex" alignItems="center" height="100%" >
                            <Typography variant="body1" marginRight="8px">
                                {tMSP("Shipping Days")}
                            </Typography>
                            <FormControl error>
                                <Input
                                    type="number"
                                    name="purchaseOrders.shippingDays"
                                    value={getFormikField(settingsFormik, "purchaseOrders.shippingDays").value}
                                    onChange={settingsFormik.handleChange}
                                    inputProps={{
                                        min: 0,
                                        step: 1,
                                    }}
                                    disableUnderline
                                    sx={{
                                        width: '60px',
                                        border: '1px solid #495057',
                                        borderRadius: '4px',
                                        padding: '2px 8px',
                                    }}
                                />
                            </FormControl>
                        </Box>
                        {Boolean(getFormikField(settingsFormik, "purchaseOrders.shippingDays").error) && (
                            <FormHelperText error>{getFormikField(settingsFormik, "purchaseOrders.shippingDays").error}</FormHelperText>)}
                    </Box>
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={tMSP("Deadline Use")} name="purchaseOrders.deadlineUse"
                        error={Boolean(getFormikField(settingsFormik, "purchaseOrders.deadlineUse").error)}
                        helperText={getFormikField(settingsFormik, "purchaseOrders.deadlineUse").error}
                        value={getFormikField(settingsFormik, "purchaseOrders.deadlineUse").value} onChange={settingsFormik.handleChange}
                    />
                </div>
                <div className="flex justify-center">
                    <Box  {...textFieldProps}>
                        <Box display="flex" alignItems="center" height="100%" >
                            <Typography variant="body1" marginRight="8px">
                                {tMSP("Tracking Days")}
                            </Typography>
                            <FormControl error>
                                <Input
                                    type="number"
                                    name="purchaseOrders.trackingDays"
                                    value={getFormikField(settingsFormik, "purchaseOrders.trackingDays").value}
                                    onChange={settingsFormik.handleChange}
                                    inputProps={{
                                        min: 0,
                                        step: 1,
                                    }}
                                    disableUnderline
                                    sx={{
                                        width: '60px',
                                        border: '1px solid #495057',
                                        borderRadius: '4px',
                                        padding: '2px 8px',
                                    }}
                                />
                            </FormControl>
                        </Box>
                        {Boolean(getFormikField(settingsFormik, "purchaseOrders.trackingDays").error) && (
                            <FormHelperText error>{getFormikField(settingsFormik, "purchaseOrders.trackingDays").error}</FormHelperText>)}
                    </Box>
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={tMSP("Ship Via")} name="purchaseOrders.shipVia"
                        error={Boolean(getFormikField(settingsFormik, "purchaseOrders.shipVia").error)}
                        helperText={getFormikField(settingsFormik, "purchaseOrders.shipVia").error}
                        value={getFormikField(settingsFormik, "purchaseOrders.shipVia").value} onChange={settingsFormik.handleChange}
                    />
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={tMSP("Default Ship")} name="purchaseOrders.defaultShip"
                        error={Boolean(getFormikField(settingsFormik, "purchaseOrders.defaultShip").error)}
                        helperText={getFormikField(settingsFormik, "purchaseOrders.defaultShip").error}
                        value={getFormikField(settingsFormik, "purchaseOrders.defaultShip").value} onChange={settingsFormik.handleChange}
                    />
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={tMSP("Purchase Order")} name="purchaseOrders.purchaseOrder"
                        error={Boolean(getFormikField(settingsFormik, "purchaseOrders.purchaseOrder").error)}
                        helperText={getFormikField(settingsFormik, "purchaseOrders.purchaseOrder").error}
                        value={getFormikField(settingsFormik, "purchaseOrders.purchaseOrder").value} onChange={settingsFormik.handleChange}
                    />
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={tMSP("Factory")} name="purchaseOrders.factory"
                        error={Boolean(getFormikField(settingsFormik, "purchaseOrders.factory").error)}
                        helperText={getFormikField(settingsFormik, "purchaseOrders.factory").error}
                        value={getFormikField(settingsFormik, "purchaseOrders.factory").value} onChange={settingsFormik.handleChange}
                    />
                </div>
            </div>
            <div className="grid grid-flow-col grid-rows-4 gap-4 pt-8 webkit-box">
                <div className="flex justify-center w-full">
                    <FormControlLabel
                        {...wideTextFieldProps}
                        className="mr-0"
                        control={
                            <Checkbox
                                value={Boolean(getFormikField(settingsFormik, "purchaseOrders.enableInstructionLine").value)}
                            />
                        }
                        label={tGC("Enable")} name="purchaseOrders.enableInstructionLine"
                        onChange={settingsFormik.handleChange}
                    />
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...wideTextFieldProps} label={tMSP("Instruction Line")} name="purchaseOrders.instructionLine"
                        disabled={!getFormikField(settingsFormik, "purchaseOrders.enableInstructionLine").value}
                        error={Boolean(getFormikField(settingsFormik, "purchaseOrders.instructionLine").error)}
                        helperText={getFormikField(settingsFormik, "purchaseOrders.instructionLine").error}
                        value={getFormikField(settingsFormik, "purchaseOrders.instructionLine").value} onChange={settingsFormik.handleChange}
                    />
                </div>
                <div className="flex justify-center w-full">
                    <FormControlLabel
                        {...wideTextFieldProps}
                        className="mr-0"
                        control={
                            <Checkbox
                                value={Boolean(getFormikField(settingsFormik, "purchaseOrders.enableFinishingLine").value)}
                            />
                        }
                        label={tGC("Enable")} name="purchaseOrders.enableFinishingLine"
                        onChange={settingsFormik.handleChange}
                    />
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...wideTextFieldProps} label={tMSP("Finishing Line")} name="purchaseOrders.finishingLine"
                        disabled={!getFormikField(settingsFormik, "purchaseOrders.enableFinishingLine").value}
                        error={Boolean(getFormikField(settingsFormik, "purchaseOrders.finishingLine").error)}
                        helperText={getFormikField(settingsFormik, "purchaseOrders.finishingLine").error}
                        value={getFormikField(settingsFormik, "purchaseOrders.finishingLine").value} onChange={settingsFormik.handleChange}
                    />
                </div>
            </div>
        </>
    );
}
import * as React from 'react';
import {Menu, MenuItem, IconButton } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useTranslation } from 'react-i18next';

export default function BasicMenu() {
  const { t: tGTI } = useTranslation([], { keyPrefix: "team.Global.Tables.ItemOptions" })
  
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const OptionsMUIIconSize = 30;

  return (
    <div>
      <IconButton
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className='text-2xl tracking-tight border-0 rounded-full'
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
    <MenuItem onClick={handleClose} style={{color: 'rgb(51, 99, 255)'}}><FileUploadIcon style={{ fontSize: OptionsMUIIconSize }} />{tGTI("Export Selected - CSV")}</MenuItem>
        <MenuItem onClick={handleClose} style={{color: 'rgb(51, 99, 255)'}}><FileUploadIcon style={{ fontSize: OptionsMUIIconSize }} />{tGTI("Export Open Orders - CSV")}</MenuItem>
        <MenuItem onClick={handleClose} style={{color: 'rgb(51, 99, 255)'}}><FileUploadIcon style={{ fontSize: OptionsMUIIconSize }} />{tGTI("Export Selected - PDF")}</MenuItem>
        <MenuItem onClick={handleClose} style={{color: 'rgb(51, 99, 255)'}}><FileUploadIcon style={{ fontSize: OptionsMUIIconSize }} />{tGTI("Export Open Orders - PDF")}</MenuItem>
        <MenuItem onClick={handleClose} style={{color: 'rgb(51, 99, 255)'}}><DeleteIcon style={{ fontSize: OptionsMUIIconSize }} />{tGTI("Delete Selected")}</MenuItem>
      </Menu>
    </div>
  );
}
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider as ReduxProvider } from "react-redux"
import './index.css';
import Router from "./routes"
import config from "./util/config";
import { store } from "./state"
import './util/i18n'; // language translation
import { Amplify } from "aws-amplify";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./util/theme";
import Toaster from "./components/toast";
import { LicenseInfo } from '@mui/x-license';
import { StyledEngineProvider } from '@mui/material/styles';
import { Buffer } from 'buffer';
import './output.css'
import './input.css'


window.Buffer = Buffer;

LicenseInfo.setLicenseKey('679b0f11f7e08fe71f601251b902e607Tz05MzkwOSxFPTE3NTIwOTcyNDMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: config.userPoolId,
      userPoolClientId: config.userPoolWebClientId,
      identityPoolId: config.identityPoolId,
      signUpVerificationMethod: "code",
    }
  },
});


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <ThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
          <>
            <Toaster />
            <Router />
          </>
        </StyledEngineProvider>
      </ThemeProvider>
    </ReduxProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

import * as Yup from 'yup';

export const taxCodeSchema = Yup.object().shape({
    codeName: Yup.string()
    .min(2, 'Too Short!')
    .max(255, 'Too Long!')
    .required('Required'),
    description: Yup.string()
    .max(255, 'Too Long!'),
    rate: Yup.number()
    .max(100, 'Rate can not be more then 100%')
})
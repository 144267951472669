import "immer"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
export interface VendorTermsModalState {
    openVendorTermsModal: boolean
    loading: boolean
}

// Initialize state
const initialState: VendorTermsModalState = {
    openVendorTermsModal: false,
    loading: false
}

export const vendorTermsModalSlice = createSlice({
    name: "vendorTermsModal",
    initialState,
    reducers: {
        openVendorTermsModal: (state) => ({
            ...state,
            openVendorTermsModal: true,
        }),
        closeModal: () => ({
            ...initialState
        }),
        setLoading: (state, action: PayloadAction<boolean>) => ({
            ...state,
            loading: action.payload,
        }),
    },
})

export const {
    openVendorTermsModal,
    closeModal,
    setLoading
} = vendorTermsModalSlice.actions
export default vendorTermsModalSlice.reducer

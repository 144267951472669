import { Switch, Box, FormControlLabel} from "@mui/material";
import type { OrderflowSettings } from "orderflow-lambdas";
import { textFieldProps, } from "../constants/form";
import { FormikProps } from "formik";
import { getFormikField } from "../../../util/fornik-helpers";
import { useTranslation } from "react-i18next";

export default function OptionsTab({
    settingsFormik,
}: {
    settingsFormik: FormikProps<OrderflowSettings>;
}): JSX.Element {
    const { t: tMSO } = useTranslation([], { keyPrefix: "team.Modals.Settings.OptionsTab" })

    return (
        <>
            <div className="grid grid-flow-col grid-rows-4 gap-4 webkit-box">
                <Box {...textFieldProps} sx={{ display: "flex", justifyContent: "start" }}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={Boolean(getFormikField(settingsFormik, "options.askForReorderRequest").value)}
                                name="options.askForReorderRequest"
                                onChange={(event) => {
                                    settingsFormik.setFieldValue(
                                        "options.askForReorderRequest",
                                        event.target.checked
                                    );
                                }}
                            />
                        }
                        label={tMSO("Ask for Re-Order Request")} name="options.askForReorderRequest"
                    />
                </Box>
                <Box {...textFieldProps} sx={{ display: "flex", justifyContent: "start" }}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={Boolean(getFormikField(settingsFormik, "options.elimateHandlingOnInvoice").value)}
                                name="options.elimateHandlingOnInvoice"
                                onChange={(event) => {
                                    settingsFormik.setFieldValue(
                                        "options.elimateHandlingOnInvoice",
                                        event.target.checked
                                    );
                                }}
                            />
                        }
                        label={tMSO("Eliminate “handling” on Invoice")} name="options.elimateHandlingOnInvoice"
                    />
                </Box>
                <Box {...textFieldProps} sx={{ display: "flex", justifyContent: "start" }}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={Boolean(getFormikField(settingsFormik, "options.printTrackingNumber").value)}
                                name="options.printTrackingNumber"
                                onChange={(event) => {
                                    settingsFormik.setFieldValue(
                                        "options.printTrackingNumber",
                                        event.target.checked
                                    );
                                }}
                            />
                        }
                        label={tMSO("Print Tracking Number on Acknowledgment & Invoices")} name="options.printTrackingNumber"
                    />
                </Box>
                <Box {...textFieldProps} sx={{ display: "flex", justifyContent: "start" }}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={Boolean(getFormikField(settingsFormik, "options.showIndustryId").value)}
                                name="options.showIndustryId"
                                onChange={(event) => {
                                    settingsFormik.setFieldValue(
                                        "options.showIndustryId",
                                        event.target.checked
                                    );
                                }}
                            />
                        }
                        label={tMSO("Show Industry ID on Purchase Orders")} name="options.showIndustryId"
                    />
                </Box>
                <Box {...textFieldProps} sx={{ display: "flex", justifyContent: "start" }}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={Boolean(getFormikField(settingsFormik, "options.notifyContactAttention").value)}
                                name="options.notifyContactAttention"
                                onChange={(event) => {
                                    settingsFormik.setFieldValue(
                                        "options.notifyContactAttention",
                                        event.target.checked
                                    );
                                }}
                            />
                        }
                        label={tMSO("Notify when Contact needs attention")} name="options.notifyContactAttention"
                    />
                </Box>
                <Box {...textFieldProps} sx={{ display: "flex", justifyContent: "start" }}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={Boolean(getFormikField(settingsFormik, "options.enableItemBrowsing").value)}
                                name="options.enableItemBrowsing"
                                onChange={(event) => {
                                    settingsFormik.setFieldValue(
                                        "options.enableItemBrowsing",
                                        event.target.checked
                                    );
                                }}
                            />
                        }
                        label={tMSO("Enable Item Browsing")} name="options.enableItemBrowsing"
                    />
                </Box>
                <Box {...textFieldProps} sx={{ display: "flex", justifyContent: "start" }}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={Boolean(getFormikField(settingsFormik, "options.notifyAddressChange").value)}
                                name="options.notifyAddressChange"
                                onChange={(event) => {
                                    settingsFormik.setFieldValue(
                                        "options.notifyAddressChange",
                                        event.target.checked
                                    );
                                }}
                            />
                        }
                        label={tMSO("Notify of address difference.")} name="options.notifyAddressChange"
                    />
                </Box>
                <Box {...textFieldProps} sx={{ display: "flex", justifyContent: "start" }}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={Boolean(getFormikField(settingsFormik, "options.changeMessageCustomerOrderHistory").value)}
                                name="options.changeMessageCustomerOrderHistory"
                                onChange={(event) => {
                                    settingsFormik.setFieldValue(
                                        "options.changeMessageCustomerOrderHistory",
                                        event.target.checked
                                    );
                                }}
                            />
                        }
                        label={tMSO("Show change message in Customer Order History.")} name="options.changeMessageCustomerOrderHistory"
                    />
                </Box>
            </div>
        </>
    );
}
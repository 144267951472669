'use client'
import { Suspense } from 'react';
import { Button, TextField, Paper, InputAdornment, CircularProgress } from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';
import MailLockIcon from '@mui/icons-material/MailLock';
import styles from "./page.module.css";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, confirmAccount, resendConfirmation, pushToast } from "../../state";
import { AuthError, ResendSignUpCodeInput } from "aws-amplify/auth";
import AccountConfirmed from "./AccountConfirmed";
import MainAppLogoIcon from '../../components/Icons/MainAppLogoIcon';

export default function Page(): JSX.Element {
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [codeErrorMessage, setCodeErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingResend, setLoadingResend] = useState(false);
  const [accountConfirmed, setAccountConfirmed] = useState(false);
  const searchParams = useSearchParams();
  const dispatch = useDispatch();

  const isValid = email && code;

  useEffect(() => {
    const _email = searchParams[0].get("email")
    if (_email) {
      const decodedEmail = decodeURIComponent(_email)
      setEmail(decodedEmail)
    }
  }, [searchParams[0].get("email")])

  const handleConfirm = async () => {
    try {
      setLoading(true)
      const input = {
        username: email,
        confirmationCode: code
      }
      const { isSignUpComplete } = await dispatch(confirmAccount(input));
      if (isSignUpComplete) setAccountConfirmed(true)
    } catch (error: unknown | AuthError) {
      if (error instanceof AuthError) {
        if (error.name === "CodeMismatchException") {
          setCodeErrorMessage("Invalid Confirmation Code.")
        }
        if (error.name === "UserNotFoundException") {
          setEmailErrorMessage("Unable to find user with that email.")
        }
        if (error.name === "NotAuthorizedException") {
          setEmailErrorMessage("This account has already been confirmed. Please log in.")
        }
      }
      console.error(error)
    } finally {
      setLoading(false)
    }
  };

  const resend = async () => {
    try {
      setLoadingResend(true)
      const input: ResendSignUpCodeInput = {
        username: email,
      }
      await dispatch(resendConfirmation(input));
      dispatch(pushToast({
        message: "Confirmation code resent.",
        type: "success"
      }))
    } catch (error: unknown | AuthError) {
      let message = "An error occurred."
      if (error instanceof AuthError) message = error.message

      dispatch(pushToast({
        message,
        type: "error"
      }))
      console.error(error)
    } finally {
      setLoadingResend(false)
    }
  };

  if (accountConfirmed) return <AccountConfirmed />

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <section className={styles.main}>
        <Paper className={styles.wrapper} elevation={3}>
          <MainAppLogoIcon className={styles.logo}/>
          <TextField
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            helperText={emailErrorMessage}
            error={Boolean(emailErrorMessage)}
            variant="standard"
            InputProps={{
              startAdornment: <InputAdornment position="start"><EmailIcon color="primary" /></InputAdornment>
            }}
          />
          <TextField
            label="Verification Code"
            value={code}
            onChange={(e) => {
              setCode(e.target.value)
            }}
            className={styles.verificationInput}
            helperText={codeErrorMessage}
            error={Boolean(codeErrorMessage)}
            fullWidth
            variant="outlined"
            InputProps={{
              startAdornment: <InputAdornment position="start"><MailLockIcon color="primary" /></InputAdornment>
            }}
          />
          <Button
            className={styles.confirmButton}
            disabled={loading || loadingResend || !isValid}
            fullWidth
            variant="contained"
            onClick={handleConfirm}>
            {loading ? <CircularProgress color="inherit" size={20} /> : "Confirm"}
          </Button>
          <Button
            className={styles.confirmButton}
            disabled={loadingResend || loading || !email}
            fullWidth
            variant="outlined"
            onClick={resend}>
            {loadingResend ? <CircularProgress color="inherit" size={20} /> : "Resend"}
          </Button>
        </Paper>
      </section>
    </Suspense>
  );
}

import { FormikProps } from "formik";

// Retrieves nested values and check errors and touched status
export function getFormikField<T>(formik: FormikProps<T>, path: string): { error: string, value: any, touched: boolean } {
    let error: any = formik.errors;
    let touched: any = formik.touched;
    let value: any = formik.values;
    const paths = path.split('.');

    for (const key of paths) {
        error = error && error[key];
        touched = touched && touched[key];
        value = value && value[key];
    }

    return {
        error: touched && error ? error : '',
        value: value ?? '',
        touched: !!touched
    };
}
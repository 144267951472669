import "immer"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface CustomerTermsModalState {
    openCustomerTermsModal: boolean
    loading: boolean
}

// Initialize state
const initialState: CustomerTermsModalState = {
    openCustomerTermsModal: false,
    loading: false
}

export const customerTermsModalSlice = createSlice({
    name: "customerTermsModal",
    initialState,
    reducers: {
        openCustomerTermsModal: (state) => ({
            ...state,
            openCustomerTermsModal: true,
        }),
        closeModal: () => ({
            ...initialState
        }),
        setLoading: (state, action: PayloadAction<boolean>) => ({
            ...state,
            loading: action.payload,
        }),
    },
})

export const {
    openCustomerTermsModal,
    closeModal,
    setLoading
} = customerTermsModalSlice.actions
export default customerTermsModalSlice.reducer


import "immer"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface SystemNumbersModalState {
    openSystemNumbersModal: boolean
    loading: boolean
}

// Initialize state
const initialState: SystemNumbersModalState = {
    openSystemNumbersModal: false,
    loading: false
}

export const systemNumbersModalSlice = createSlice({
    name: "systemNumbersModal",
    initialState,
    reducers: {
        openSystemNumbersModal: (state) => ({
            ...state,
            openSystemNumbersModal: true,
        }),
        closeModal: () => ({
            ...initialState
        }),
        setLoading: (state, action: PayloadAction<boolean>) => ({
            ...state,
            loading: action.payload,
        }),
    },
})

export const {
    openSystemNumbersModal,
    closeModal,
    setLoading
} = systemNumbersModalSlice.actions
export default systemNumbersModalSlice.reducer

import * as React from "react"
import styles from "./tabs.module.sass"
import {
  Tabs as MuiTabs,
  Tab,
  TabProps as MuiTabProps,
} from "@mui/material"

export interface TabLabelProps {
  label: string
  badge?: string | number
}

export function TabLabel({
  label,
  badge,
}: TabLabelProps) {
  return (
    <div className="cp_component_tabs-tab-label">
      { label }
      { badge !== undefined
      && (
      <span className="cp_component_tabs-tab-chip">
        { badge }
      </span>
      )
      }
    </div>
  )
}

interface TabProps extends Omit<MuiTabProps, "label">, TabLabelProps {
}

export interface TabsProps {
  tabs: TabProps[],
  /**
   *
   * @param index index of selected tab.
   */
  handleChange?: (index: number) => void
  className?: string
  /**
   * If you are controlling the value of the tabs, use this prop. This is needed for example if
   * you want to control the value of the tabs from a parent component using url params.
   */
  controlledValue?: number
  defaultValue?: number
  controls?: React.ReactNode
}

const classes = [ styles.tabs ]

/**
 *
 * Use the handleChange callback and the index to show/hide elements by
 * providing a hidden prop to an element.
 * For example <div hidden={index === 1}>
 */
export function Tabs({
  tabs,
  handleChange,
  className,
  defaultValue,
  controlledValue,
  controls,
}: TabsProps) {
  const classNamesProp = className?.split(" ") || []
  const classNames = classes.concat(classNamesProp).join(" ")

  const [ value, setValue ] = React.useState(defaultValue || 0)

  const onChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
    if (handleChange) handleChange(newValue)
  }

  if (controls) {
    return (
      <div className="cp_component_tabs-container">
        <MuiTabs
          selectionFollowsFocus={ true }
          value={ controlledValue || value }
          onChange={ onChange }
          className={ classNames }
        >
          {
          tabs.map((props) => (
            <Tab
              key={ props.label }
              { ...props }
              label={ <TabLabel { ...props } /> }
              className={styles.tab}
            />

          ))
        }
        </MuiTabs>
        <div className="cp_component_tabs-controls">
          { controls }
        </div>
      </div>
    )
  }
  return (
    <MuiTabs
      selectionFollowsFocus={ true }
      value={ value }
      onChange={ onChange }
      className={ classNames }
    >
      {
          tabs.map((props) => (
            <Tab
              key={ props.label }
              { ...props }
              label={ <TabLabel { ...props } /> }
              className={styles.tab}
            />

          ))
        }
    </MuiTabs>
  )
}
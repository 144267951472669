export const formWidth = "1100px";

export const maxNotesLength = 1500

export const gridPaymentItemProps = {
    item: true,
    xs: 12,
    sx: { display: "flex", justifyContent: "center" },
};



'use client'
import { Button, Paper, Typography } from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import styles from "./page.module.css";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../util";

export default function AccountConfirmed(): JSX.Element {
const navigate = useNavigate();


  return (
      <section className={styles.main}>
        <Paper className={styles.wrapper} elevation={3}>
          <CheckCircleOutlineIcon 
            color="success"
            className={styles.checkIcon}
          />
          <Typography>
            Your email has been confirmed.
          </Typography>
          <Typography>
            You are now able to log into your new account.
          </Typography>
          <Button
            className={styles.loginButton}
            fullWidth
            endIcon={<NavigateNextIcon />}
            variant="contained"
            onClick={() => {
              navigate(Routes.LOGIN)
            }}>
            Log In
          </Button>
        </Paper>
      </section>
  );
}

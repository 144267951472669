/* eslint-disable no-unused-vars */
export enum Routes {
    LOGIN = "/login",
    SIGNUP = "/signup",
    FORGOT_PASSWORD = "/forgot-password",
    FORGOT_PASSWORD_RESET = "/forgot-password/reset-password",
    SIGNUP_CONFIRM = "/signup-confirm",
    HOME = "/my-team",
    NOT_FOUND = "/404",
    // Customers
    HOME_CUSTOMERS = "/my-team/customers",
    HOME_VENDORS = "/my-team/vendors",
    HOME_ITEMS = "/my-team/items"
}

export const publicRoutes: string[] = [
    Routes.LOGIN,
    Routes.SIGNUP,
    Routes.FORGOT_PASSWORD,
    Routes.SIGNUP_CONFIRM
];

export const privateRoutes: string[] = [
    Routes.HOME,
    Routes.HOME_CUSTOMERS,
    Routes.HOME_VENDORS,
    Routes.HOME_ITEMS,
];

export const TOAST_DEFAULT_TIMEOUT_MS = 6000;
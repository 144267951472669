import { useEffect, useState } from "react";
import { Box, Button, IconButton, Container, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, Typography, Divider, TextField, Tooltip, Radio, InputAdornment } from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useFormik } from "formik";
// TODO update input when api is ready
import type { VendorTerms, VendorTermsCreateInput, VendorTermsUpdateInput } from "../../../../../../packages/orderflow-lambdas/src/shared/api/models/vendorTerms";
import { useTranslation } from "react-i18next";
import { buttonWidth, textFieldProps } from "../constants/form";
import { pushToast, useDispatch, useSelector } from "../../../state";
import { closeModal, setLoading } from "../../../state/vendorTermsModalSlice";
import { getFormikField } from "../../../util/fornik-helpers";
import { vendorTermsSchema } from "./schemas/VendorTermsSchema";
import { TRPCClientError } from "@trpc/client";
import { serverError } from "../constants/errors";
import { client } from "../../../util";

const columnPadding = "0 2px"


const initialValues: VendorTermsCreateInput = {
    name: "",
    description: "",
    days: 0,
    weight: 0,
};

export default function VendorTermsModal() {
    const { t: tGC } = useTranslation([], { keyPrefix: "team.Global.Common" })
    const { t: tGlobal } = useTranslation([], { keyPrefix: "team.Global.Buttons" })
    const { t: tPLB } = useTranslation([], { keyPrefix: "team.PageLayout.LB" })
    const { t: tMV } = useTranslation([], { keyPrefix: "team.Modals.VendorTerms" })

    const dispatch = useDispatch();

    const { openVendorTermsModal, loading } = useSelector(({ vendorTermsModalSlice }) => vendorTermsModalSlice);

    const [vendorTerms, setVendorTerms] = useState<VendorTerms[]>([]);
    const [vendorTermsBeforeEdit, setVendorTermsBeforeEdit] = useState<VendorTerms>();

    // edit Mode
    const [editMode, setEditMode] = useState<boolean>(false);

    const resetState = () => {
        formik.resetForm()
    };

    const handleClose = () => {
        dispatch(closeModal());
        resetState();
    };

    const handleAddVendorTerms = async (payload: VendorTermsCreateInput) => {
        dispatch(setLoading(true))
        try {
            const modifiedPayload = {
                ...payload,
                days: Number(payload.days), // Explicitly convert to number
                weight: Number(payload.weight) / 100, // Explicitly convert to number
                description: payload.description,
            };

            const vendorTerm = await client.createVendorTerm.mutate(modifiedPayload);
            setVendorTerms([...vendorTerms, vendorTerm]);
            formik.resetForm();
            dispatch(pushToast({ message: tMV("Term created successfully!"), type: "success" }));
        } catch (error) {
            if (error instanceof TRPCClientError) {
                dispatch(pushToast({ message: error.message, type: "error" }));
            } else {
                dispatch(pushToast({ message: serverError, type: "error" }));
            }
        }
        dispatch(setLoading(false))
    }

    // Formik initialization
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: vendorTermsSchema,
        onSubmit: handleAddVendorTerms,
    });

    const columns: GridColDef[] = [
        { field: "name", headerName: tMV("Terms Code") + "*", flex: 1 },
        { field: "description", headerName: tMV("Terms Description"), flex: 1 },
        {
            field: "default",
            headerName: "Default",
            flex: 0.5,
            renderCell: (params) => {
                const selectedVendorTerm = vendorTerms.find((vendorTerm) => vendorTerm.VendorTermId === params.id);

                if (!selectedVendorTerm) return <></>
                
                const handleCheckDefault = () => {
                    handleChangeDefault(selectedVendorTerm)
                };

                return (
                    <div>
                        <Radio checked={selectedVendorTerm.default} onClick={handleCheckDefault} />
                    </div>
                )

            },
        },
        {
            field: "actions",
            headerName: tGlobal("Actions"),
            width: 80,
            flex: 0.5,
            renderCell: (params) => {
                const handleEdit = () => {
                    handleEditVendorTerm(params.id)
                };

                const handleDelete = () => {
                    handleDeleteVendorTerm(params.id)
                };

                return (
                    <div>
                        <IconButton onClick={handleEdit}>
                            <EditIcon />
                        </IconButton>
                        <IconButton onClick={handleDelete}>
                            <DeleteIcon />
                        </IconButton>
                    </div>
                );
            },
        }
    ];

    const handleChangeDefault = async (newDefaultVendorTerm: VendorTerms) => {
        dispatch(setLoading(true))
        try {
            await client.setDefaultVendorTerm.mutate(newDefaultVendorTerm.VendorTermId);
            const defaultPT = vendorTerms.find((vendorTerm) => vendorTerm.default === true);
            if (defaultPT) {
                defaultPT.default = false
                setVendorTerms((prevVendorTerms) =>
                    prevVendorTerms.map((vendorTerms) =>
                        vendorTerms.VendorTermId === defaultPT.VendorTermId ? defaultPT : vendorTerms
                    )
                );
            }
            newDefaultVendorTerm.default = true
            setVendorTerms((prevVendorTerms) =>
                prevVendorTerms.map((vendorTerms) =>
                    vendorTerms.VendorTermId === newDefaultVendorTerm.VendorTermId ? newDefaultVendorTerm : vendorTerms
                )
            );
        } catch (error) {
            if (error instanceof TRPCClientError) {
                dispatch(pushToast({ message: error.message, type: "error" }));
            } else {
                dispatch(pushToast({ message: serverError, type: "error" }));
            }
        }
        dispatch(setLoading(false))
    }

    const handleDeleteVendorTerm = async (id: any) => {
        dispatch(setLoading(true))
        try {
            await client.deleteVendorTerm.mutate(id);
            // Updating the vendorTerms state by filtering out the deleted tax code
            setVendorTerms((prevVendorTerms) =>
                prevVendorTerms.filter((vendorTerm) => vendorTerm.VendorTermId !== id)
            );
        } catch (error) {
            if (error instanceof TRPCClientError) {
                dispatch(pushToast({ message: error.message, type: "error" }));
            } else {
                dispatch(pushToast({ message: serverError, type: "error" }));
            }
        }
        dispatch(setLoading(false))
    }

    const handleEditVendorTerm = (id: any) => {
        const selectedVendorTerm = vendorTerms.find((vendorTerm) => vendorTerm.VendorTermId === id);

        if (!selectedVendorTerm) return console.log("Vendor Term not found with ID:", id);

        formik.setFieldValue("name", selectedVendorTerm.name);
        formik.setFieldValue("days", selectedVendorTerm.days);
        formik.setFieldValue("description", selectedVendorTerm.description);
        formik.setFieldValue("weight", selectedVendorTerm.weight * 100);
        setVendorTermsBeforeEdit(selectedVendorTerm)
        setEditMode(true)
    };

    const handleEditVendorTermSave = async () => {
        dispatch(setLoading(true))
        try {
            if (!vendorTermsBeforeEdit) return console.log("VendorTerms for edit was not selected.");
            const modifiedPayload: VendorTermsUpdateInput = {
                ...formik.values,
                days: Number(formik.values.days), // Explicitly convert to number
                weight: Number(formik.values.weight) / 100, // Explicitly convert to number
                VendorTermId: vendorTermsBeforeEdit.VendorTermId,
            };
            const updatedVendorTerms = await client.updateVendorTerm.mutate(modifiedPayload);
            setVendorTerms((prevVendorTerms) =>
                prevVendorTerms.map((vendorTerms) =>
                    vendorTerms.VendorTermId === updatedVendorTerms.VendorTermId ? updatedVendorTerms : vendorTerms
                )
            );

            formik.resetForm(); // Reset the secondary Formik form
            setVendorTermsBeforeEdit(undefined); // Reset the saved original contact data
            setEditMode(false);
            dispatch(pushToast({ message: tMV("Term saved successfully!"), type: "success" }));
        } catch (error) {
            if (error instanceof TRPCClientError) {
                dispatch(pushToast({ message: error.message, type: "error" }));
            } else {
                dispatch(pushToast({ message: serverError, type: "error" }));
            }
        }
        dispatch(setLoading(false))
    };

    const handleEditVendorTermCancel = async () => {
        formik.resetForm();
        setEditMode(false)
    };

    useEffect(() => {
        if (!openVendorTermsModal) return;
        const fetchData = async () => {
            dispatch(setLoading(true))
            try {
                const resp = await client.allVendorTerms.query();
                setVendorTerms(resp.results)
            } catch (error) {
                if (error instanceof TRPCClientError) {
                    dispatch(pushToast({ message: error.message, type: "error" }));
                } else {
                    dispatch(pushToast({ message: serverError, type: "error" }));
                }
            }
            dispatch(setLoading(false))
        };
        fetchData();
    }, [openVendorTermsModal]);

    return (
        <Dialog
            open={openVendorTermsModal}
            onClose={(event, reason) => {
                if (reason && reason === "backdropClick")
                    return;
                handleClose()
            }}
            maxWidth="lg"
            scroll="paper"
            PaperProps={{ className: "w-full" }}
        >
            <DialogTitle sx={{ m: 0, p: 1 }}>{tPLB("Menu.Utilities.Vendor Terms")}</DialogTitle  >
            <IconButton disabled={loading} color="primary" sx={{ position: "absolute", right: 4, top: 4 }} onClick={handleClose}>
                <CloseIcon />
            </IconButton>
            <DialogContent dividers sx={{ p: 3 }} >
                {loading ? (
                    <Box height="400px" display="flex" justifyContent="center" alignItems="center">
                        <CircularProgress size={80} />
                    </Box>
                ) : (
                    <>
                        <Box sx={{ display: "flex", justifyContent: "start" }}>
                            <div className="grid grid-flow-col grid-rows-2 gap-4 md:w-3/6 webkit-box">
                                <TextField variant="filled" {...textFieldProps} label={tMV("Terms Code")} name="name"
                                    error={Boolean(getFormikField(formik, "name").error)}
                                    helperText={getFormikField(formik, "name").error}
                                    value={getFormikField(formik, "name").value} onChange={formik.handleChange}
                                />
                                <TextField variant="filled" {...textFieldProps} label={tMV("Terms Description")} name="description"
                                    error={Boolean(getFormikField(formik, "description").error)}
                                    helperText={getFormikField(formik, "description").error}
                                    value={getFormikField(formik, "description").value} onChange={formik.handleChange}
                                />
                                <TextField variant="filled" {...textFieldProps} label={tGC("Days")} name="days" type="number"
                                    error={Boolean(getFormikField(formik, "days").error)}
                                    helperText={getFormikField(formik, "days").error}
                                    value={getFormikField(formik, "days").value} onChange={formik.handleChange}
                                />
                                <TextField variant="filled" {...textFieldProps} label={tGC("Weight")} name="weight" type="number"
                                    error={Boolean(getFormikField(formik, "weight").error)}
                                    helperText={getFormikField(formik, "weight").error}
                                    value={getFormikField(formik, "weight").value} onChange={formik.handleChange}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                %
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                            <Box sx={{ paddingX: 2 }}>
                                <Box sx={{ paddingY: 1, paddingX: 1, display: "flex", justifyContent: "space-between" }}>
                                    {editMode ? (
                                        <>
                                            <Tooltip title={tGlobal("Save")}>
                                                <IconButton size="small" sx={{ p: 0, marginRight: 2 }} color="primary" onClick={handleEditVendorTermSave}>
                                                    <SaveIcon cursor={"pointer"} fontSize={"large"} />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title={tGlobal("Cancel")}>
                                                <IconButton size="small" sx={{ p: 0 }} onClick={handleEditVendorTermCancel}>
                                                    <CancelIcon cursor={"pointer"} fontSize={"large"} />
                                                </IconButton>
                                            </Tooltip></>
                                    ) : (
                                        <Tooltip sx={{ paddingX: 5 }} title={tGlobal("Add Vendor Term")} placement="bottom">
                                            <AddCircleIcon onClick={() => { formik.submitForm() }} cursor={"pointer"} fontSize={"large"} />
                                        </Tooltip>
                                    )}
                                </Box>
                            </Box>
                        </Box >
                        <Typography color="primary" sx={{ marginTop: 2 }} >{tGC("Terms")}</Typography>
                        <Divider />
                        <div style={{ height: 400, width: "100%" }}>
                            <DataGridPro
                                sx={{
                                    border: 0, // Removes all borders
                                    "& .MuiDataGrid-cell": {
                                        padding: columnPadding, // Adjust the padding as needed
                                    },
                                    "& .MuiDataGrid-columnHeader": {
                                        padding: columnPadding, // Adjust the padding as needed
                                    },
                                    ".MuiDataGrid-columnHeaderTitle": { fontWeight: "bold" }
                                }}
                                hideFooter
                                disableColumnSorting
                                disableColumnFilter
                                rows={vendorTerms && vendorTerms.map((vendorTerm) => {
                                    return {
                                        id: vendorTerm.VendorTermId,
                                        name: vendorTerm.name,
                                        default: vendorTerm.default,
                                        description: vendorTerm.description,
                                    }
                                })}
                                columns={columns}
                                pageSizeOptions={[vendorTerms?.length || 0]} // Make sure this number is equal to or greater than the number of rows to display all at once
                            />
                        </div>
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Box width="100%" sx={{ display: "flex", justifyContent: "end" }}>
                    <Button disabled={loading} variant="outlined" color="primary" sx={{ width: buttonWidth }} onClick={handleClose}>{tGlobal("Cancel")}</Button>
                </Box>
            </DialogActions>
        </Dialog >
    );
}

import "immer"
import { createSlice, PayloadAction} from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import type { Customer, CustomerSearchOutput, CustomerSearchInput } from "orderflow-lambdas"
import ApiClient from "../../util/api-client"
type SearchBy = CustomerSearchInput['searchBy']

export interface CustomerBrowseState {
  searchInput: string
  results?: CustomerSearchOutput
  searchBy: SearchBy
  isLoading: boolean,
  activeSelection?: Customer
}

// Initialize state
const initialState: CustomerBrowseState = {
  searchInput: '',
  results: undefined,
  searchBy: 'companyName',
  isLoading: false,
  activeSelection: undefined
}

export const customerBrowseSlice = createSlice({
  name: "customerBrowse",
  initialState,
  reducers: {
    setSearchInput: (state, action: PayloadAction<string>) => ({
      ...state,
      searchInput: action.payload
    }),
    setSearchBy: (state, action: PayloadAction<SearchBy>) => ({
      ...state,
      searchBy: action.payload
    }),
    setResults: (state, action: PayloadAction<CustomerSearchOutput | undefined>) => ({
      ...state,
      results: action.payload
    }),
    setLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isLoading: action.payload
    }),
    setActiveSelection: (state, action: PayloadAction<Customer>) => ({
      ...state,
      activeSelection: action.payload
    })
  },
})

export const { 
  setSearchInput,
  setSearchBy,
  setResults,
  setLoading,
  setActiveSelection
} = customerBrowseSlice.actions
export default customerBrowseSlice.reducer

// Thunks
export const searchCustomers = (searchInput: CustomerSearchInput) => async (dispatch: Dispatch): Promise<void> => {
  try {
      dispatch(setLoading(true))
      const results = await ApiClient.searchCustomers.query(searchInput)
      dispatch(setResults(results))
  } catch (error: unknown) {
      dispatch(setResults(undefined))
  } finally {
      dispatch(setLoading(false))
  }
}

type environments = "development" | "production" | "qa" | "local"

interface Configuration {
    userPoolId: string
    region: string
    userPoolWebClientId: string
    identityPoolId: string
}

const development: Configuration = {
    userPoolId: "us-east-1_p7x4S9iRz",
    region: "us-east-1",
    identityPoolId: "us-east-1:968572e5-12ff-47f2-9425-d949f4285968",
    userPoolWebClientId: "4jbp2dd7s55anke6atbmsn09ql"
}
const qa: Configuration = {
    userPoolId: "us-east-1_QtMCri0p7",
    region: "us-east-1",
    identityPoolId: "us-east-1:4b19b2f3-cffb-4637-a758-29b5e27eb945",
    userPoolWebClientId: "f2c5oh73vrm61dhil29vuaelt"
}
const production: Configuration = {
    userPoolId: "us-east-1_klAcBmw0g",
    region: "us-east-1",
    identityPoolId: "us-east-1:4e4c010a-6419-426d-952a-aea023a43003",
    userPoolWebClientId: "3jd2jaobcaoblumbqgv70n776k"
}

// eslint-disable-next-line no-unused-vars
const configurations: {[K in environments]: Configuration} = {
    local: development,
    development: development,
    qa: qa,
    production: production,
}

if(process.env.REACT_APP_ENVIRONMENT !== "development"
    && process.env.REACT_APP_ENVIRONMENT !== "production"
    && process.env.REACT_APP_ENVIRONMENT !== "qa"
    && process.env.REACT_APP_ENVIRONMENT !== "local"
    ) {
        console.log(`environment ${JSON.stringify(process.env)}`)
        console.log(`Using configuration for ${process.env.REACT_APP_ENVIRONMENT}`)
        console.error(`Please set a value for the environment variable REACT_APP_ENVIRONMENT. Valid values are "development", "production", and "qa"`)   
        throw new Error("Invalid environment")
}
console.log(`Using configuration for ${process.env.REACT_APP_ENVIRONMENT}`)

export default configurations[process.env.REACT_APP_ENVIRONMENT]
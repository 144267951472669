import { Box, Typography, TextField, Grid, Checkbox, Radio, FormControlLabel, MenuItem, Divider } from "@mui/material";
import type { OrderflowSettings } from "orderflow-lambdas";
import { textFieldProps } from "../constants/form";
import { FormikProps } from "formik";
import { getFormikField } from "../../../util/fornik-helpers";
import { gridPaymentItemProps } from "../customers/createAndEdit/constants";
import Table from "./SalesTaxTab/Table";
import { useTranslation } from "react-i18next";

export default function SalesTaxTab({
    settingsFormik,
}: {
    settingsFormik: FormikProps<OrderflowSettings>;
}): JSX.Element {
    const { t: tMSS } = useTranslation([], { keyPrefix: "team.Modals.Settings.SalesTaxTab" })

    return (
        <>
            <Typography color="primary">{tMSS("CORPORATION SALES TAX")}</Typography>
            <Divider sx={{ marginBottom: 2 }} />
            <Grid container>
                {/* INFO in the future we might need it
                <Grid container spacing={2} item xs={12} sm={6}>
                    <Grid {...gridPaymentItemProps}>
                        <TextField variant="filled" {...textFieldProps} label={"Sales Tax Code"} name="salesTax.codeName" type="text"
                            error={Boolean(getFormikField(settingsFormik, "salesTax.codeName").error)}
                            helperText={getFormikField(settingsFormik, "salesTax.codeName").error}
                            value={getFormikField(settingsFormik, "salesTax.codeName").value} onChange={settingsFormik.handleChange}
                        />
                    </Grid>
                    <Grid {...gridPaymentItemProps}>
                        <TextField variant="filled" {...textFieldProps} label={"Tax Rate %"} name="salesTax.rate" type="number"
                            error={Boolean(getFormikField(settingsFormik, "salesTax.rate").error)}
                            helperText={getFormikField(settingsFormik, "salesTax.rate").error}
                            value={getFormikField(settingsFormik, "salesTax.rate").value} onChange={settingsFormik.handleChange}
                        />
                    </Grid>
                </Grid> */}
                <Grid container spacing={2} item xs={12} sm={6}>
                    <Grid {...gridPaymentItemProps}>
                        <Box width={"100%"} maxWidth={"300px"} sx={{ display: "flex", justifyContent: "start" }}>
                            <Typography sx={{ marginTop: 2 }} >Sales Tax Reporting Method</Typography>
                        </Box>
                    </Grid>
                    <Grid {...gridPaymentItemProps}>
                        <Box {...textFieldProps} sx={{ display: "flex", justifyContent: "center" }}>
                            <FormControlLabel control={<Radio checked={getFormikField(settingsFormik, "salesTax.reportingMethod").value == "Cash Basis" && true} />}
                                label={tMSS("Cash Basis")}
                                name="salesTax.cashBasis"
                                onChange={() => {
                                    settingsFormik.setFieldValue("salesTax.reportingMethod", "Cash Basis");
                                }}
                            />
                            <FormControlLabel control={<Radio checked={getFormikField(settingsFormik, "salesTax.reportingMethod").value == "Accrual Basis" && true} />}
                                label={tMSS("Accrual Basis")}
                                name="salesTax.accrualBasis"
                                onChange={() => {
                                    settingsFormik.setFieldValue("salesTax.reportingMethod", "Accrual Basis");
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={2} item xs={12} sm={6}>
                    <Grid {...gridPaymentItemProps}>
                        <TextField variant="filled" {...textFieldProps} label={tMSS("Tax Exempt ID No.")} name="salesTax.exemptId" type="number"
                            error={Boolean(getFormikField(settingsFormik, "salesTax.exemptId").error)}
                            helperText={getFormikField(settingsFormik, "salesTax.exemptId").error}
                            value={getFormikField(settingsFormik, "salesTax.exemptId").value} onChange={settingsFormik.handleChange}
                        />
                    </Grid>
                    <Grid {...gridPaymentItemProps}>
                        <Box {...textFieldProps} sx={{ display: "flex", justifyContent: "center" }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        value={Boolean(getFormikField(settingsFormik, "salesTax.isFreightExempt").value)}
                                    />
                                }
                                label={tMSS("Check if Freight is Exempt")} name="salesTax.isFreightExempt"
                                onChange={settingsFormik.handleChange}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <div className="w-full mt-8">
                <Table />
            </div>
        </>
    );
}
import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { setSearchBy } from '../../state/vendorBrowseSlice';
import { useDispatch, useSelector } from '../../state';
import { VendorSearchInput } from 'orderflow-lambdas';
import { useTranslation } from 'react-i18next';

export default function BasicSelect() {
  const dispatch = useDispatch();
  const { t: tGC } = useTranslation([], { keyPrefix: "team.Global.Common" })
  const { t: tGT } = useTranslation([], { keyPrefix: "team.Global.Tables" })
  const { t: tCV } = useTranslation([], { keyPrefix: "team.Components.VendorBrowseTable" })

  const { searchBy } = useSelector((store) => store.vendorBrowseSlice);


  const handleChange = (event: SelectChangeEvent) => {
    dispatch(setSearchBy(event.target.value as VendorSearchInput['searchBy']))
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{tGT("Search By")}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={searchBy}
          label={tGT("Search By")}
          onChange={handleChange}
        >
          <MenuItem value={'code'}>{tCV("Vendor Code")}</MenuItem>
          <MenuItem value={'vendorName'}>{tGC("Company Name")}</MenuItem>
          <MenuItem value={'contact'}>{tGC("Contact")}</MenuItem>
          <MenuItem value={'city'}>{tGC("City")}</MenuItem>
          <MenuItem value={'phone'}>{tGC("Tel. No.")}</MenuItem>
          <MenuItem value={'email'}>{tGC("Email")}</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
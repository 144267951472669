import { Box, Typography, TextField, Grid, Checkbox, FormControlLabel, MenuItem, Divider } from "@mui/material";
import type { CustomerCreate, PaymentTerms, TaxCode } from "orderflow-lambdas";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import usaStates from "../../../../static/usaStates.json";
import { gridPaymentItemProps } from "./constants";
import { gridItemProps, textFieldProps, textFieldSelectProps } from "../../constants/form";
import dayjs, { Dayjs } from "dayjs";
import { FormikProps } from "formik";
import { getFormikField } from "../../../../util/fornik-helpers";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

export default function CustomerAndPayment({
    formik,
    paymentTerms,
    taxCodes,
}: {
    formik: FormikProps<CustomerCreate>;
    paymentTerms: PaymentTerms[];
    taxCodes: TaxCode[];
}): JSX.Element {
    const { t: tGC } = useTranslation([], { keyPrefix: "team.Global.Common" })
    const { t: tAddC } = useTranslation([], { keyPrefix: "team.Modals.Customers.createAndEdit.Add Customer" })
    const lastOrderDate = formik.values.payment?.lastOrderDate ? dayjs(formik.values.payment.lastOrderDate) : null;

    const handleChangeLastOrderDate = (newValue: Dayjs | null) => {
        // Convert the new date value to Unix time (seconds since the Unix Epoch)
        const unixTime = newValue ? newValue.unix() : null;

        // Update Formik's state with Unix time
        formik.setFieldValue("payment.lastOrderDate", unixTime);
    };

    useEffect(() => {
        if (paymentTerms.length > 0) {
            const defaultTerm = paymentTerms.find((pT) => pT.default);
            if (defaultTerm) {
                formik.setFieldValue("payment.paymentTerms", [defaultTerm]);
            }
        }
    }, [paymentTerms]); // Only run when `paymentTerms` changes

    const selectedPaymentTerm = getFormikField(formik, "payment.paymentTerms").value

    useEffect(() => {
        if (taxCodes.length > 0) {
            const defaultCode = taxCodes.find((pT) => pT.default);
            if (defaultCode) {
                formik.setFieldValue("payment.taxCode", defaultCode);
            }
        }
    }, [taxCodes]); // Only run when `taxCodes` changes

    const selectedTaxCode = getFormikField(formik, "payment.taxCode").value

    return (
        <>
            <Typography color="primary">{tAddC("CUSTOMER")}</Typography>
            <Divider sx={{ marginBottom: 1 }} />
            <Grid container spacing={2}>
                <Grid {...gridItemProps}>
                    <TextField required variant="filled" {...textFieldProps} label={tAddC("Company Name")} name="details.companyName"
                        error={Boolean(getFormikField(formik, "details.companyName").error)}
                        helperText={getFormikField(formik, "details.companyName").error}
                        value={getFormikField(formik, "details.companyName").value} onChange={formik.handleChange}
                    />
                </Grid>
                <Grid  {...gridItemProps}>
                    <TextField variant="filled" {...textFieldProps} label={tGC("Website")} name="details.website"
                        error={Boolean(getFormikField(formik, "details.website").error)}
                        helperText={getFormikField(formik, "details.website").error}
                        value={getFormikField(formik, "details.website").value} onChange={formik.handleChange}
                    />
                </Grid>
                <Grid  {...gridItemProps}>
                    <TextField variant="filled" {...textFieldProps} label={tGC("Address")} name="details.address"
                        error={Boolean(getFormikField(formik, "details.address").error)}
                        helperText={getFormikField(formik, "details.address").error}
                        value={getFormikField(formik, "details.address").value} onChange={formik.handleChange}
                    />
                </Grid>
                <Grid  {...gridItemProps}>
                    <TextField variant="filled" {...textFieldProps} label={tGC("Email")} name="details.email"
                        error={Boolean(getFormikField(formik, "details.email").error)}
                        helperText={getFormikField(formik, "details.email").error}
                        value={getFormikField(formik, "details.email").value} onChange={formik.handleChange}
                    />
                </Grid>
                <Grid  {...gridItemProps}>
                    <TextField variant="filled" {...textFieldProps} label={tGC("City")} name="details.city"
                        error={Boolean(getFormikField(formik, "details.city").error)}
                        helperText={getFormikField(formik, "details.city").error}
                        value={getFormikField(formik, "details.city").value} onChange={formik.handleChange}
                    />
                </Grid>
                <Grid  {...gridItemProps}>
                    <TextField variant="filled" {...textFieldProps} label={tGC("Tel. No.")} name="details.phone"
                        error={Boolean(getFormikField(formik, "details.phone").error)}
                        helperText={getFormikField(formik, "details.phone").error}
                        value={getFormikField(formik, "details.phone").value} onChange={formik.handleChange}
                    />
                </Grid>
                <Grid  {...gridItemProps}>
                    <TextField variant="filled" {...textFieldProps} {...textFieldSelectProps} label={tGC("State")} name="details.state" select
                        error={Boolean(getFormikField(formik, "details.state").error)}
                        helperText={getFormikField(formik, "details.state").error}
                        value={getFormikField(formik, "details.state").value} onChange={formik.handleChange}
                    >
                        <MenuItem value=""><em>None</em></MenuItem>
                        {usaStates.map((state) => (
                            <MenuItem key={state.abbreviation} value={state.abbreviation}>
                                {state.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid  {...gridItemProps}>
                    <TextField variant="filled" {...textFieldProps} label={tAddC("Other")} name="details.other"
                        error={Boolean(getFormikField(formik, "details.other").error)}
                        helperText={getFormikField(formik, "details.other").error}
                        value={getFormikField(formik, "details.other").value} onChange={formik.handleChange}
                    />
                </Grid>
                <Grid  {...gridItemProps}>
                    <TextField variant="filled" {...textFieldProps} label={tGC("Zip Code")} name="details.zipCode"
                        error={Boolean(getFormikField(formik, "details.zipCode").error)}
                        helperText={getFormikField(formik, "details.zipCode").error}
                        value={getFormikField(formik, "details.zipCode").value} onChange={formik.handleChange}
                    />
                </Grid>
                <Grid  {...gridItemProps}>
                    <TextField variant="filled" {...textFieldProps} label={tGC("Preferred Method of Communication")} name="details.preferredCommunication"
                        error={Boolean(getFormikField(formik, "details.preferredCommunication").error)}
                        helperText={getFormikField(formik, "details.preferredCommunication").error}
                        value={getFormikField(formik, "details.preferredCommunication").value} onChange={formik.handleChange}
                    />
                </Grid>
                <Grid  {...gridItemProps}>
                    <TextField disabled variant="filled" {...textFieldProps} {...textFieldSelectProps} label={tGC("Country")} name="details.country" select
                        error={Boolean(getFormikField(formik, "details.country").error)}
                        helperText={getFormikField(formik, "details.country").error}
                        value={getFormikField(formik, "details.country").value} onChange={formik.handleChange}
                    >
                        <MenuItem value="US">US</MenuItem>
                    </TextField>
                </Grid>
            </Grid>
            <Typography color="primary" sx={{ marginTop: 2 }} >PAYMENT</Typography>
            <Divider sx={{ marginBottom: 1 }} />
            <Grid container>
                <Grid container spacing={2} item xs={12} sm={6} alignItems="flex-start">
                    <Grid {...gridPaymentItemProps}>
                        <Box width={"100%"} maxWidth={"350px"} sx={{ display: "flex", justifyContent: "start" }}>
                            <FormControlLabel disabled control={<Checkbox />} label={tAddC("Statement")} />
                            <FormControlLabel disabled control={<Checkbox />} label={tAddC("Apply Service Charge")} />
                        </Box>
                    </Grid>
                    <Grid {...gridPaymentItemProps}>
                        <Box width={"100%"} maxWidth={"300px"} sx={{ display: "flex", justifyContent: "space-between" }}>
                            <TextField variant="filled" disabled fullWidth sx={{ maxWidth: "100px", mr: 1 }} label={tAddC("Monthly Service Charge")} />
                            <Typography alignSelf={"center"}>{tAddC("%After")}</Typography>
                            <TextField variant="filled" disabled fullWidth sx={{ maxWidth: "100px", mx: 1 }} />
                            <Typography alignSelf={"center"}>{tGC("Days")}</Typography>
                        </Box>
                    </Grid>
                    <Grid {...gridPaymentItemProps}>
                        <Box width={"100%"} maxWidth={"350px"} sx={{ display: "flex", justifyContent: "start" }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        value={getFormikField(formik, "payment.taxable").value}
                                    />
                                }
                                label={tAddC("Taxable")} name="payment.taxable"
                                onChange={formik.handleChange}
                            />
                        </Box>
                    </Grid>
                    <Grid {...gridPaymentItemProps}>
                        <Box width={"100%"} maxWidth={"300px"} sx={{ display: "flex", justifyContent: "space-between" }}>
                            <TextField variant="filled" fullWidth sx={{ maxWidth: "140px" }} label={tAddC("Tax Code")} defaultValue="STG" select name="payment.taxCode"
                                error={Boolean(getFormikField(formik, "payment.taxCode").error)}
                                helperText={getFormikField(formik, "payment.taxCode").error}
                                value={selectedTaxCode ? selectedTaxCode.TaxCodeId : ""}
                                onChange={(event) => {
                                    const selectedTerm = taxCodes.find((pT) => pT.TaxCodeId === event.target.value);
                                    formik.setFieldValue("payment.taxCode", selectedTerm ? selectedTerm : undefined);
                                }}
                            >
                                <MenuItem value={""}>{tGC("None")}</MenuItem>
                                {taxCodes.map((pT) => {
                                    return (
                                        <MenuItem key={pT.TaxCodeId} value={pT.TaxCodeId}>
                                            {pT.codeName}
                                        </MenuItem>
                                    )
                                }
                                )}
                            </TextField>
                            <TextField variant="filled" fullWidth sx={{ maxWidth: "140px" }} label={tAddC("Exempt Code")} name="payment.exemptCode"
                                error={Boolean(getFormikField(formik, "payment.exemptCode").error)}
                                helperText={getFormikField(formik, "payment.exemptCode").error}
                                value={getFormikField(formik, "payment.exemptCode").value} onChange={formik.handleChange}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={2} item xs={12} sm={6}>
                    <Grid {...gridPaymentItemProps}>
                        <TextField variant="filled" {...textFieldProps} {...textFieldSelectProps} label={tAddC("Payment Terms")} select name="payment.paymentTerms"
                            error={Boolean(getFormikField(formik, "payment.paymentTerms").error)}
                            helperText={getFormikField(formik, "payment.paymentTerms").error}
                            value={Array.isArray(selectedPaymentTerm) && selectedPaymentTerm.length > 0 ? selectedPaymentTerm[0].PaymentTermId : ""}
                            onChange={(event) => {
                                const selectedTerm = paymentTerms.find((pT) => pT.PaymentTermId === event.target.value);
                                formik.setFieldValue("payment.paymentTerms", selectedTerm ? [selectedTerm] : []);
                            }}
                        >
                            <MenuItem value={""}>{tGC("None")}</MenuItem>
                            {paymentTerms.map((pT) => {
                                return (
                                    <MenuItem key={pT.PaymentTermId} value={pT.PaymentTermId}>
                                        {pT.name}
                                    </MenuItem>
                                )
                            }
                            )}
                        </TextField>
                    </Grid>
                    <Grid {...gridPaymentItemProps}>
                        <TextField variant="filled" {...textFieldProps} label={tAddC("Current Balance")} name="payment.currentBalance" type="number"
                            error={Boolean(getFormikField(formik, "payment.currentBalance").error)}
                            helperText={getFormikField(formik, "payment.currentBalance").error}
                            value={getFormikField(formik, "payment.currentBalance").value} onChange={formik.handleChange}
                        />
                    </Grid>
                    <Grid {...gridPaymentItemProps}>
                        <TextField variant="filled" {...textFieldProps} label={tAddC("Credit Limit")} type="number" name="payment.creditLimit"
                            error={Boolean(getFormikField(formik, "payment.creditLimit").error)}
                            helperText={getFormikField(formik, "payment.creditLimit").error}
                            value={getFormikField(formik, "payment.creditLimit").value} onChange={formik.handleChange}
                        />
                    </Grid>
                    <Grid {...gridPaymentItemProps}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Last Order Date"
                                {...textFieldProps}
                                value={lastOrderDate}
                                onChange={handleChangeLastOrderDate}
                                views={["year", "month", "day"]}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}
import { Paper } from "@mui/material";
import styles from "./index.module.css"
import { useMemo } from "react";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import { Routes } from "../../util";
import { Tabs, TabLabelProps, } from "../../components";
import AuthProvider from "../../components/AuthProvider";
import LogInTab from "./LoginTab";
import RegisterTab from "./RegisterTab";
import TeamProvider from "../../components/TeamProvider";

enum TabPathsEnum {
  LOGIN = "login",
  REGISTER = "register"
}

interface LogInTabLabelProps extends TabLabelProps {
  path: TabPathsEnum.LOGIN | TabPathsEnum.REGISTER
}

const tabLabels: LogInTabLabelProps[] = [
  { label: "Login", path: TabPathsEnum.LOGIN },
  { label: "Register", path: TabPathsEnum.REGISTER }
]

export default function Page(): JSX.Element {
  const navigate = useNavigate();
  const searchParams = useSearchParams();
  const location = useLocation();

  const handleTabChange = (newValue: number) => {
    navigate(`${Routes.LOGIN}?${tabLabels[newValue]?.path ? `tab=${tabLabels[newValue]?.path}` : ""}`)
  };

  const tabIndex = useMemo(() => {
    const foundTab = tabLabels.find(({ path }) => path === searchParams[0].get("tab"))
    return foundTab ? tabLabels.indexOf(foundTab) : 0
  }, [searchParams[0].get("tab"), location.pathname])

  return (
    <AuthProvider>
        <section className={styles.main}>
          <Paper className={styles.wrapper} elevation={3}>
            <img
              src={window.location.origin + "/logo-black.png"}
              alt="logo"
              className={styles.logo}
              // width={logo.width}
              // height={logo.height}
            />
            <Tabs
              handleChange={handleTabChange}
              defaultValue={tabIndex}
              controlledValue={tabIndex}
              tabs={tabLabels}
            >
            </Tabs>
            {tabIndex === 0 && <LogInTab />}
            {tabIndex === 1 && <RegisterTab />}
          </Paper>
        </section>
    </AuthProvider>
  );
}

import { Box, TextField, MenuItem, FormControlLabel, Checkbox, useTheme } from "@mui/material";
import type { OrderflowSettings, PaymentTerms } from "orderflow-lambdas";
import hexToRGBA from "../../../util/converters/hexToRGBA";
import { textFieldProps, textFieldSelectProps, wideTextFieldProps } from "../constants/form";
import { FormikProps } from "formik";
import { getFormikField } from "../../../util/fornik-helpers";
import { useTranslation } from "react-i18next";

export default function InvoicesTab({
    settingsFormik,
    paymentTerms,
}: {
    settingsFormik: FormikProps<OrderflowSettings>;
    paymentTerms: PaymentTerms[];
}): JSX.Element {
    const theme = useTheme();
    const { t: tGC } = useTranslation([], { keyPrefix: "team.Global.Common" })
    const { t: tMSI } = useTranslation([], { keyPrefix: "team.Modals.Settings.InvoicesTab" })

    return (
        <>
            <div className="grid grid-cols-2 gap-4 webkit-box">
                <div className="flex justify-center col-start-1 col-end-1">
                    <TextField variant="filled" {...textFieldProps} label={tMSI("Invoice")} name="invoices.invoice"
                        error={Boolean(getFormikField(settingsFormik, "invoices.invoice").error)}
                        helperText={getFormikField(settingsFormik, "invoices.invoice").error}
                        value={getFormikField(settingsFormik, "invoices.invoice").value} onChange={settingsFormik.handleChange}
                    />
                </div>
                <div className="flex justify-center col-start-2 col-end-2">
                    <TextField variant="filled" {...textFieldProps} label={tMSI("Sold to")} name="invoices.soldTo"
                        error={Boolean(getFormikField(settingsFormik, "invoices.soldTo").error)}
                        helperText={getFormikField(settingsFormik, "invoices.soldTo").error}
                        value={getFormikField(settingsFormik, "invoices.soldTo").value} onChange={settingsFormik.handleChange}
                    />
                </div>
                <div className="flex justify-center col-start-1 col-end-1">
                    <TextField variant="filled" {...textFieldProps} {...textFieldSelectProps} label={tGC("Terms")} select name="invoices.terms"
                        error={Boolean(getFormikField(settingsFormik, "invoices.terms").error)}
                        helperText={getFormikField(settingsFormik, "invoices.terms").error}
                        value={getFormikField(settingsFormik, "invoices.terms").value?.PaymentTermId || ""} // Check for PaymentTermId or default to ""
                        onChange={(event) => {
                            const selectedTerm = paymentTerms.find((pT) => pT.PaymentTermId === event.target.value);
                            settingsFormik.setFieldValue("invoices.terms", selectedTerm || undefined);
                        }}
                    >
                        <MenuItem value={""}>{tGC("None")}</MenuItem>
                        {paymentTerms.map((pT) => (
                            <MenuItem key={pT.PaymentTermId} value={pT.PaymentTermId}>
                                {pT.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                <div className="flex justify-center col-start-2 col-end-2">
                    <TextField variant="filled" {...textFieldProps} label={tMSI("Ship to")} name="invoices.shipTo"
                        error={Boolean(getFormikField(settingsFormik, "invoices.shipTo").error)}
                        helperText={getFormikField(settingsFormik, "invoices.shipTo").error}
                        value={getFormikField(settingsFormik, "invoices.shipTo").value} onChange={settingsFormik.handleChange}
                    />
                </div>
                <Box {...textFieldProps} sx={{ display: "flex", justifyContent: "center" }} className="col-start-1 col-end-1">
                    <FormControlLabel
                        control={
                            <Checkbox
                                value={Boolean(getFormikField(settingsFormik, "invoices.showCustomerAddress").value)}
                            />
                        }
                        label={tMSI("Do not show customer’s email address")} name="invoices.showCustomerAddress"
                        onChange={settingsFormik.handleChange}
                    />
                </Box>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={tMSI("Date Shipped")} name="invoices.dateShipped"
                        error={Boolean(getFormikField(settingsFormik, "invoices.dateShipped").error)}
                        helperText={getFormikField(settingsFormik, "invoices.dateShipped").error}
                        value={getFormikField(settingsFormik, "invoices.dateShipped").value} onChange={settingsFormik.handleChange}
                    />
                </div>
                <div className="flex justify-center col-start-2 col-end-2">
                    <TextField variant="filled" {...textFieldProps} label={tMSI("Shipped Method")} name="invoices.shippedMethod"
                        error={Boolean(getFormikField(settingsFormik, "invoices.shippedMethod").error)}
                        helperText={getFormikField(settingsFormik, "invoices.shippedMethod").error}
                        value={getFormikField(settingsFormik, "invoices.shippedMethod").value} onChange={settingsFormik.handleChange}
                    />
                </div>
            </div>
            <div className="grid grid-flow-col grid-rows-5 gap-4 pt-8 webkit-box">
                <div className="flex justify-center w-full">
                    <FormControlLabel
                        {...wideTextFieldProps}
                        className="mr-0"
                        control={
                            <Checkbox
                                value={Boolean(getFormikField(settingsFormik, "invoices.returnStubEnabled").value)}
                            />
                        }
                        label={tGC("Enable")} name="invoices.returnStubEnabled"
                        onChange={settingsFormik.handleChange}
                    />
                </div>
                <div className="flex justify-center row-span-2">
                    <TextField variant="filled" label={tMSI("Return Stub")} name="invoices.returnStub"
                        multiline
                        rows={4}
                        sx={{
                            ...wideTextFieldProps.sx,
                            backgroundColor: hexToRGBA(theme.palette.primary.main, 0.08)
                        }}
                        disabled={!getFormikField(settingsFormik, "invoices.returnStubEnabled").value}
                        error={Boolean(getFormikField(settingsFormik, "invoices.returnStub").error)}
                        helperText={getFormikField(settingsFormik, "invoices.returnStub").error}
                        value={getFormikField(settingsFormik, "invoices.returnStub").value} onChange={settingsFormik.handleChange}
                    />
                </div>
                <div className="flex justify-center w-full">
                    <FormControlLabel
                        {...wideTextFieldProps}
                        className="mr-0"
                        control={
                            <Checkbox
                                value={Boolean(getFormikField(settingsFormik, "invoices.enableFinishingLine").value)}
                            />
                        }
                        label={tGC("Enable")} name="invoices.enableFinishingLine"
                        onChange={settingsFormik.handleChange}
                    />
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...wideTextFieldProps} label={tMSI("Finishing Line")} name="invoices.finishingLine"
                        disabled={!getFormikField(settingsFormik, "invoices.enableFinishingLine").value}
                        error={Boolean(getFormikField(settingsFormik, "invoices.finishingLine").error)}
                        helperText={getFormikField(settingsFormik, "invoices.finishingLine").error}
                        value={getFormikField(settingsFormik, "invoices.finishingLine").value} onChange={settingsFormik.handleChange}
                    />
                </div>
            </div>
        </>
    );
}
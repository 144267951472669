import { useEffect, useState } from "react";
import { Box, Button, IconButton, Container, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, Typography, Divider, TextField, Tooltip } from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useFormik } from "formik";
// TODO update input when api is ready
import type { ShipMethods, ShipMethodsCreateInput, ShipMethodsUpdateInput } from "../../../../../../packages/orderflow-lambdas/src/shared/api/models/shipMethods";
import { useTranslation } from "react-i18next";
import { buttonWidth, textFieldProps } from "../constants/form";
import { pushToast, useDispatch, useSelector } from "../../../state";
import { closeModal, setLoading } from "../../../state/shipMethodsModalSlice";
import { getFormikField } from "../../../util/fornik-helpers";
import { shipMethodsSchema } from "./schemas/shipMethodsSchema";
import { TRPCClientError } from "@trpc/client";
import { serverError } from "../constants/errors";
import { client } from "../../../util";

const columnPadding = "0 2px"


const initialValues: ShipMethodsCreateInput = {
    name: "",
};

export default function ShipMethodsModal() {
    const { t: tGlobal } = useTranslation([], { keyPrefix: "team.Global.Buttons" })

    const dispatch = useDispatch();

    const { openShipMethodsModal, loading } = useSelector(({ shipMethodsModalSlice }) => shipMethodsModalSlice);

    const [shipMethods, setShipMethods] = useState<ShipMethods[]>([]);
    const [shipMethodsBeforeEdit, setShipMethodsBeforeEdit] = useState<ShipMethods>();

    // edit Mode
    const [editMode, setEditMode] = useState<boolean>(false);

    const resetState = () => {
        formik.resetForm()
    };

    const handleClose = () => {
        dispatch(closeModal());
        resetState();
    };

    const handleAddShipMethods = async (payload: ShipMethodsCreateInput) => {
        dispatch(setLoading(true))
        try {
            const modifiedPayload = {
                ...payload,
            };

            // TODO connect api when ready
            // const paymentTerm = await client.createShipMethod.mutate(modifiedPayload);
            // setShipMethods([...shipMethods, paymentTerm]);
            formik.resetForm();
            dispatch(pushToast({ message: "Term created successfully!", type: "success" }));
        } catch (error) {
            if (error instanceof TRPCClientError) {
                dispatch(pushToast({ message: error.message, type: "error" }));
            } else {
                dispatch(pushToast({ message: serverError, type: "error" }));
            }
        }
        dispatch(setLoading(false))
    }

    // Formik initialization
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: shipMethodsSchema,
        onSubmit: handleAddShipMethods,
    });

    const columns: GridColDef[] = [
        { field: "name", headerName: "Method*", flex: 1 },
        {
            field: "actions",
            headerName: tGlobal("Actions"),
            width: 80,
            flex: 0.5,
            renderCell: (params) => {
                const handleEdit = () => {
                    handleEditShipMethod(params.id)
                };

                const handleDelete = () => {
                    handleDeleteShipMethod(params.id)
                };

                return (
                    <div>
                        <IconButton onClick={handleEdit}>
                            <EditIcon />
                        </IconButton>
                        <IconButton onClick={handleDelete}>
                            <DeleteIcon />
                        </IconButton>
                    </div>
                );
            },
        }
    ];


    const handleDeleteShipMethod = async (id: any) => {
        dispatch(setLoading(true))
        try {
            // TODO connect api when ready
            // await client.deleteShipMethod.mutate(id);
            // Updating the shipMethods state by filtering out the deleted tax code
            setShipMethods((prevShipMethods) =>
                prevShipMethods.filter((paymentTerm) => paymentTerm.ShipMethodId !== id)
            );
        } catch (error) {
            if (error instanceof TRPCClientError) {
                dispatch(pushToast({ message: error.message, type: "error" }));
            } else {
                dispatch(pushToast({ message: serverError, type: "error" }));
            }
        }
        dispatch(setLoading(false))
    }

    const handleEditShipMethod = (id: any) => {
        const selectedShipMethod = shipMethods.find((paymentTerm) => paymentTerm.ShipMethodId === id);
        if (selectedShipMethod) {
            formik.setFieldValue("name", selectedShipMethod.name);
            setShipMethodsBeforeEdit(selectedShipMethod)
            setEditMode(true)
        } else {
            console.log("TaxCode not found with ID:", id);
        }
    };

    const handleEditShipMethodSave = async () => {
        dispatch(setLoading(true))
        try {
            if (shipMethodsBeforeEdit) {
                const modifiedPayload: ShipMethodsUpdateInput = {
                    ...formik.values,
                    ShipMethodId: shipMethodsBeforeEdit.ShipMethodId,
                };
                // TODO connect api when ready
                // const updatedShipMethods = await client.updateShipMethod.mutate(modifiedPayload);
                // setShipMethods((prevShipMethods) =>
                //     prevShipMethods.map((shipMethods) =>
                //         shipMethods.ShipMethodId === updatedShipMethods.ShipMethodId ? updatedShipMethods : shipMethods
                //     )
                // );

                formik.resetForm(); // Reset the secondary Formik form
                setShipMethodsBeforeEdit(undefined); // Reset the saved original contact data
                setEditMode(false);
                dispatch(pushToast({ message: "Term saved successfully!", type: "success" }));
            } else {
                // this should never happened. Before edit save user should always select ShipMethod that wants to edit
                console.log("ShipMethods for edit was not selected.");
            }
        } catch (error) {
            if (error instanceof TRPCClientError) {
                dispatch(pushToast({ message: error.message, type: "error" }));
            } else {
                dispatch(pushToast({ message: serverError, type: "error" }));
            }
        }
        dispatch(setLoading(false))
    };

    const handleEditShipMethodCancel = async () => {
        formik.resetForm();
        setEditMode(false)
    };

    useEffect(() => {
        const fetchData = async () => {
            dispatch(setLoading(true))
            try {
                // TODO connect api when ready
                // const resp = await client.allShipMethods.query();
                // setShipMethods(resp.results)
            } catch (error) {
                if (error instanceof TRPCClientError) {
                    dispatch(pushToast({ message: error.message, type: "error" }));
                } else {
                    dispatch(pushToast({ message: serverError, type: "error" }));
                }
            }
            dispatch(setLoading(false))
        };
        fetchData();
    }, []);

    return (
        <Dialog
            open={openShipMethodsModal}
            onClose={(event,reason)=>{
                if (reason && reason === "backdropClick") 
                    return;
                handleClose()
            }}
            maxWidth="lg"
            scroll="paper"
            PaperProps={{ className: "w-full" }}
        >
            <DialogTitle sx={{ m: 0, p: 1 }}>{"Shipping Methods"}</DialogTitle  >
            <IconButton disabled={loading} color="primary" sx={{ position: "absolute", right: 4, top: 4 }} onClick={handleClose}>
                <CloseIcon />
            </IconButton>
            <DialogContent dividers sx={{ p: 3 }} >
                {loading ? (
                    <Box height="400px" display="flex" justifyContent="center" alignItems="center">
                        <CircularProgress size={80} />
                    </Box>
                ) : (
                    <>
                        <Box sx={{ display: "flex", justifyContent: "start" }}>
                            <div className="grid grid-flow-col grid-rows-1 gap-4 md:w-2/6 webkit-box">
                                <TextField variant="filled" {...textFieldProps} label={"Method"} name="name"
                                    error={Boolean(getFormikField(formik, "name").error)}
                                    helperText={getFormikField(formik, "name").error}
                                    value={getFormikField(formik, "name").value} onChange={formik.handleChange}
                                />
                            </div>
                            <Box sx={{ paddingX: 2 }}>
                                <Box sx={{ paddingY: 1, paddingX: 1, display: "flex", justifyContent: "space-between" }}>
                                    {editMode ? (
                                        <>
                                            <Tooltip title={tGlobal("Save")}>
                                                <IconButton size="small" sx={{ p: 0, marginRight: 2 }} color="primary" onClick={handleEditShipMethodSave}>
                                                    <SaveIcon cursor={"pointer"} fontSize={"large"} />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title={tGlobal("Cancel")}>
                                                <IconButton size="small" sx={{ p: 0 }} onClick={handleEditShipMethodCancel}>
                                                    <CancelIcon cursor={"pointer"} fontSize={"large"} />
                                                </IconButton>
                                            </Tooltip></>
                                    ) : (
                                        <Tooltip sx={{ paddingX: 5 }} title={tGlobal("Add Shipping Method")} placement="bottom">
                                            <AddCircleIcon onClick={() => { formik.submitForm() }} cursor={"pointer"} fontSize={"large"} />
                                        </Tooltip>
                                    )}
                                </Box>
                            </Box>
                        </Box >
                        <Typography color="primary" sx={{ marginTop: 2 }} >{"Terms"}</Typography>
                        <Divider />
                        <div style={{ height: 400, width: "100%" }}>
                            <DataGridPro
                                sx={{
                                    border: 0, // Removes all borders
                                    "& .MuiDataGrid-cell": {
                                        padding: columnPadding, // Adjust the padding as needed
                                    },
                                    "& .MuiDataGrid-columnHeader": {
                                        padding: columnPadding, // Adjust the padding as needed
                                    },
                                    ".MuiDataGrid-columnHeaderTitle": { fontWeight: "bold" }
                                }}
                                hideFooter
                                disableColumnSorting
                                disableColumnFilter
                                rows={shipMethods && shipMethods.map((paymentTerm) => {
                                    return {
                                        id: paymentTerm.ShipMethodId,
                                        name: paymentTerm.name,
                                    }
                                })}
                                columns={columns}
                                pageSizeOptions={[shipMethods?.length || 0]} // Make sure this number is equal to or greater than the number of rows to display all at once
                            />
                        </div>
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Box width="100%" sx={{ display: "flex", justifyContent: "end" }}>
                    <Button disabled={loading} variant="outlined" color="primary" sx={{ width: buttonWidth }} onClick={handleClose}>{tGlobal("Cancel")}</Button>
                </Box>
            </DialogActions>
        </Dialog >
    );
}

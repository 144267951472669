import React, { useEffect, useState } from "react";
import { Tabs, Tab, Box, Button, IconButton, Container, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import type { OrderflowSettings, PaymentTerms } from "orderflow-lambdas";
import { useTranslation } from "react-i18next";
import { buttonWidth } from "../constants/form";
import { pushToast, useDispatch, useSelector } from "../../../state";
import { closeModal, setLoading } from "../../../state/settingsModalSlice";
import SalesTaxTab from "./SalesTaxTab";
import SettingsTab from "./SettingsTab";
import PurchaseOrdersTab from "./PurchaseOrdersTab";
import AcknowledgmentsTab from "./AcknowledgmentsTab";
import InvoicesTab from "./InvoicesTab";
import { TRPCClientError } from "@trpc/client";
import { client } from "../../../util";
import { serverError } from "../constants/errors";
import OptionsTab from "./OptionsTab";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const settingsFormikIV: OrderflowSettings = {
    TeamId: "",
    salesTax: {
        exemptId: "",
        reportingMethod: "Cash Basis",
        isFreightExempt: false
    },
    SettingsId: "",
    updatedAt: 0,
    createdAt: 0,
    options: {
        askForReorderRequest: false,
        elimateHandlingOnInvoice: false,
        printTrackingNumber: false,
        showIndustryId: false,
        notifyContactAttention: false,
        enableItemBrowsing: false,
        notifyAddressChange: false,
        changeMessageCustomerOrderHistory: false,
    },
    general: {
        companyName: "",
        employerId: "",
        additionalIds: ["", ""],
        address: "",
        city: "",
        state: "",
        zip: "",
        country: "",
        website: "",
        email: ""
    },
    purchaseOrders: {
        shipTo: "",
        approximateShipDate: "",
        factory: "",
        purchaseOrder: "",
        shippingDays: 0,
        deadlineUse: "",
        trackingDays: 0,
        defaultShip: "",
        shipVia: "",
        enableInstructionLine: false,
        instructionLine: "",
        finishingLine: "",
        enableFinishingLine: false,
    },
    acknowledgements: {
        soldTo: "",
        shipTo: "",
        approximateShipDate: "",
        deadlineUse: "",
        showCustomerTerms: false,
        acknowledgement: "",
        quantity: "",
        shipVia: "",
        enableInstructionLine: false,
        instructionLine: "",
        finishingLine: "",
        enableFinishingLine: false,
        defaultShip: "",
    },
    invoices: {
        invoice: "",
        terms: {
            PaymentTermId: "",
            TeamId: "",
            createdAt: 0,
            updatedAt: 0,
            name: "",
            days: 0,
            default: false,
            weight: 0,
            createdBy: "",
        },
        soldTo: "",
        shipTo: "",
        dateShipped: "",
        shippedMethod: "",
        returnStub: "",
        returnStubEnabled: false,
        finishingLine: "",
        enableFinishingLine: false,
        showCustomerAddress: false,
    }
};


export default function SettingsModal() {
    const { t: tGlobal } = useTranslation([], { keyPrefix: "team.Global.Buttons" })
    const { t: tPLB } = useTranslation([], { keyPrefix: "team.PageLayout.LB" })
    const { t: tMS } = useTranslation([], { keyPrefix: "team.Modals.Settings" })

    const { openSettingsModal, loading } = useSelector(({ settingsModalSlice }) => settingsModalSlice);

    const [tab, setTab] = useState(0);
    const dispatch = useDispatch();

    const [paymentTerms, setPaymentTerms] = useState<PaymentTerms[]>([]);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    const resetState = () => {
        setTab(0);
        settingsFormik.resetForm()
    };

    const handleClose = () => {
        dispatch(closeModal());
        resetState();
    };

    const settingsHandleSave = async (payload: OrderflowSettings) => {
        dispatch(setLoading(true))
        try {
            const resp = await client.updateSettings.mutate(payload);
            settingsFormik.setValues({
                ...resp
            });
            dispatch(pushToast({ message: tMS("Settings Saved!"), type: "success" }));
        } catch (error) {
            if (error instanceof TRPCClientError) {
                dispatch(pushToast({ message: error.message, type: "error" }));
            } else {
                dispatch(pushToast({ message: serverError, type: "error" }));
            }
        }
        dispatch(setLoading(false))
        handleClose()
    }

    // Formik initialization
    const settingsFormik = useFormik({
        initialValues: settingsFormikIV,
        // validationSchema: settingsSchema, TODO add validation when api is ready
        onSubmit: settingsHandleSave,
    });

    useEffect(() => {
        if (!openSettingsModal) return;
        const fetchData = async () => {
            dispatch(setLoading(true))
            // Get payment terms
            try {
                const resp = await client.allPaymentTerms.query();
                setPaymentTerms(resp.results)
            } catch (error) {
                if (error instanceof TRPCClientError) {
                    dispatch(pushToast({ message: error.message, type: "error" }));
                } else {
                    dispatch(pushToast({ message: serverError, type: "error" }));
                }
                handleClose()
            }

            // Get settings
            try {
                const resp = await client.getSettings.query();
                settingsFormik.setValues({
                    ...resp
                });
            } catch (error) {
                if (error instanceof TRPCClientError) {
                    dispatch(pushToast({ message: error.message, type: "error" }));
                } else {
                    dispatch(pushToast({ message: serverError, type: "error" }));
                }
                handleClose()
            }
            dispatch(setLoading(false))
        };
        fetchData();
    }, [openSettingsModal]);

    return (
        <Dialog
            open={openSettingsModal}
            onClose={(event,reason)=>{
                if (reason && reason === "backdropClick") 
                    return;
                handleClose()
            }}
            maxWidth="lg"
            scroll="paper"
            PaperProps={{ className: "w-full" }}
        >
            <DialogTitle sx={{ m: 0, p: 1 }}>{tPLB("Menu.Utilities.Settings")}</DialogTitle  >
            <IconButton disabled={loading} color="primary" sx={{ position: "absolute", right: 4, top: 4 }} onClick={handleClose}>
                <CloseIcon />
            </IconButton>
            <DialogContent dividers sx={{ p: 1 }} >
                <Container>
                    <Tabs centered value={tab} onChange={handleTabChange} sx={{
                        "& .MuiTabs-flexContainer": {
                            justifyContent: "space-between",
                        },
                    }}>
                        <Tab sx={{ fontWeight: "bold" }} label={tMS("SETTINGS")} {...a11yProps(0)} />
                        <Tab sx={{ fontWeight: "bold" }} label={tMS("SALES TAX")} {...a11yProps(1)} />
                        <Tab sx={{ fontWeight: "bold" }} label={tMS("PURCHASE ORDERS")} {...a11yProps(2)} />
                        <Tab sx={{ fontWeight: "bold" }} label={tMS("ACKNOWLEDGMENTS")} {...a11yProps(3)} />
                        <Tab sx={{ fontWeight: "bold" }} label={tMS("INVOICES")} {...a11yProps(4)} />
                        <Tab sx={{ fontWeight: "bold" }} label={tMS("OPTIONS")} {...a11yProps(5)} />
                    </Tabs>
                </Container>
                <TabPanel value={tab} index={0}>
                    <SettingsTab settingsFormik={settingsFormik} />
                </TabPanel>
                <TabPanel value={tab} index={1}>
                    <SalesTaxTab settingsFormik={settingsFormik} />
                </TabPanel>
                <TabPanel value={tab} index={2}>
                    <PurchaseOrdersTab settingsFormik={settingsFormik} />
                </TabPanel>
                <TabPanel value={tab} index={3}>
                    <AcknowledgmentsTab settingsFormik={settingsFormik} />
                </TabPanel>
                <TabPanel value={tab} index={4}>
                    <InvoicesTab settingsFormik={settingsFormik} paymentTerms={paymentTerms} />
                </TabPanel>
                <TabPanel value={tab} index={5}>
                    <OptionsTab settingsFormik={settingsFormik} />
                </TabPanel>
            </DialogContent>
            <DialogActions>
                <Box width="100%" sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Button disabled={loading} variant="contained" color="primary" sx={{ width: buttonWidth }} onClick={() => {
                        settingsFormik.submitForm()
                    }}>{loading ? <CircularProgress size={20} /> : tGlobal("Save")}</Button>
                    <Button disabled={loading} variant="outlined" color="primary" sx={{ width: buttonWidth }} onClick={handleClose}>{tGlobal("Cancel")}</Button>
                </Box>
            </DialogActions>
        </Dialog >
    );
}

"use client";
import { createTheme, ThemeOptions } from "@mui/material/styles";

const themeOptions: ThemeOptions = {
    palette: {
        primary: {
            main: "#3363FF",
            dark: "#2345B2",
            light: "#5B82FF",
        },
        secondary: {
            main: "#8833FF",
            dark: "#5F23B2",
            light: "#9F5BFF",
        },
        success: {
            main: "#2E7D32",
            dark: "#2E7D32",
            light: "#2E7D32",
        }
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    ...(ownerState.color === "primary" && {
                        backgroundColor: "#F5F5F5",
                    }),
                }),
            }
        }
    }
}

export const theme = createTheme(themeOptions);


export default theme;
import * as Yup from 'yup';

export const paymentTermsSchema = Yup.object().shape({
    name: Yup.string()
    .min(2, 'Too Short!')
    .max(255, 'Too Long!')
    .required('Required'),
    days: Yup.number(),
    weight: Yup.number()
    .min(0,"Weight must 0 to 100!")
    .max(100,"Weight must 0 to 100!")
})
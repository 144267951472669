import "immer"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import type { Vendor, VendorDeleteInput } from "orderflow-lambdas"
import ApiClient from "../../util/api-client"

export interface VendorPopupModalState {
  openVendorPopupModal: boolean
  loading: boolean
}

// Initialize state
const initialState: VendorPopupModalState = {
  openVendorPopupModal: false,
  loading: false
}

export const vendorPopupModalSlice = createSlice({
  name: "vendorPopupModal",
  initialState,
  reducers: {
    openVendorPopupModal: (state) => ({
      ...state,
      openVendorPopupModal: true,
    }),
    closeModal: () => ({
      ...initialState
    }),
    setLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      loading: action.payload,
    }),
  },
})

export const {
  openVendorPopupModal,
  closeModal,
  setLoading
} = vendorPopupModalSlice.actions
export default vendorPopupModalSlice.reducer

// Thunks
export const deleteVendor = (deleteInput: VendorDeleteInput) => async (dispatch: Dispatch): Promise<void> => {
  try {
    dispatch(setLoading(true))
    await ApiClient.deleteVendor.mutate(deleteInput)
  } finally {
    dispatch(setLoading(false))
  }
}

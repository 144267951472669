import "immer"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Dispatch } from "redux"
import type { Customer, CustomerDeleteInput } from "orderflow-lambdas"
import ApiClient from "../../util/api-client"

export interface CustomerPopupModalState {
  openCustomerPopupModal: boolean
  loading: boolean
}

// Initialize state
const initialState: CustomerPopupModalState = {
  openCustomerPopupModal: false,
  loading: false
}

export const customerPopupModalSlice = createSlice({
  name: "customerPopupModal",
  initialState,
  reducers: {
    openCustomerPopupModal: (state) => ({
      ...state,
      openCustomerPopupModal: true,
    }),
    closeModal: () => ({
      ...initialState
    }),
    setLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      loading: action.payload,
    }),
  },
})

export const {
  openCustomerPopupModal,
  closeModal,
  setLoading
} = customerPopupModalSlice.actions
export default customerPopupModalSlice.reducer

// Thunks
export const deleteCustomer = (deleteInput: CustomerDeleteInput) => async (dispatch: Dispatch): Promise<void> => {
  try {
    dispatch(setLoading(true))
    await ApiClient.deleteCustomer.mutate(deleteInput)
  } finally {
    dispatch(setLoading(false))
  }
}

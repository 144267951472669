'use client'
import React, { JSX } from "react"
import { Snackbar, Alert, Typography } from "@mui/material"

import { useSelector, InternalToast } from "../../state"

function renderToast(toast: InternalToast): JSX.Element {
  return (
    <Snackbar
      key={toast.id}
      open={true}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert
        severity={toast.type}
        variant="filled"
        sx={{ width: '100%' }}
      >
        <Typography fontWeight={600}>
          {toast.message}
        </Typography>
        {
          toast.additionalInfo && (
            <>
              {toast.additionalInfo}
            </>
          )
        }
      </Alert>
    </Snackbar>
  );
}
export default function Toaster(): JSX.Element {
  const { toasts } = useSelector(({ toastsSlice }) => toastsSlice)
  return (
    <div className="cp_component_toaster">
      { toasts.map(renderToast) }
    </div>
  )
}
import { Typography, TextField, Grid, MenuItem, Divider, Box, Tooltip, useTheme, IconButton } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import hexToRGBA from "../../../../util/converters/hexToRGBA";
import { useEffect, useState } from "react";
import { textFieldProps, textFieldSelectProps } from "../../constants/form";
import usaStates from "../../../../static/usaStates.json";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { FormikProps, useFormik } from "formik";
import { customerContactSchema } from "./schemas/indexSchema";
import { CustomerCreate, CustomerContactCreate } from "orderflow-lambdas";
import { getFormikField } from "../../../../util/fornik-helpers";
import { useTranslation } from "react-i18next";

const columnPadding = "0 2px"

// We create a new type and add id in it because MUI DataGrid works only with ID
type ContactWithId = CustomerContactCreate & { id: string };

export default function ContactAndNotes({
    formik,
}: {
    formik: FormikProps<CustomerCreate>;
}): JSX.Element {
    const { t: tGlobal } = useTranslation([], { keyPrefix: "team.Global.Buttons" })
    const { t: tGC } = useTranslation([], { keyPrefix: "team.Global.Common" })
    const { t: tAddC } = useTranslation([], { keyPrefix: "team.Modals.Customers.createAndEdit.Add Customer" })
    const theme = useTheme();

    // Basically MUI Grid requires Id to handle rows, but provided api structure does not have ids.
    // Because of that we need to create this state and use it for table.
    // In the functions below we update formik on every event. Basically we update 2 arrays at the same time (one has ids).
    const [contactsWithId, setContactsWithId] = useState<ContactWithId[]>([]);

    // edit Mode
    const [editMode, setEditMode] = useState<boolean>(false);
    const [contactBeforeEdit, setContactBeforeEdit] = useState<CustomerContactCreate>();

    const initialValues: CustomerContactCreate = {};

    const formikContact = useFormik({
        initialValues: initialValues,
        validationSchema: customerContactSchema,
        onSubmit: (values) => {
            alert(JSON.stringify(values, null, 2));
        },
    });

    const columns: GridColDef[] = [
        { field: "contactName", headerName: tGC("Name"), width: 110 },
        { field: "address", headerName: tGC("Address"), width: 110 },
        { field: "city", headerName: tGC("City"), width: 90 },
        { field: "state", headerName: tGC("State"), width: 60 },
        { field: "zipCode", headerName: tGC("Zip Code"), width: 65 },
        { field: "country", headerName: tGC("Country"), width: 65 },
        { field: "position", headerName: tAddC("Position"), width: 70 },
        { field: "phone", headerName: tGC("Tel. No."), width: 120 },
        { field: "email", headerName: tGC("Email"), width: 120 },
        { field: "preferredCommunication", headerName: tAddC("Preferred Contact"), width: 130 },
        {
            field: "actions",
            headerName: tGlobal("Actions"),
            width: 80,
            renderCell: (params) => {
                const handleEdit = () => {
                    handleEditContact(params.id)

                };

                const handleDelete = () => {
                    handleDeleteContact(params.id)
                };

                return (
                    <div>
                        <IconButton onClick={handleEdit}>
                            <EditIcon />
                        </IconButton>
                        <IconButton onClick={handleDelete}>
                            <DeleteIcon />
                        </IconButton>
                    </div>
                );
            },
        }
    ];

    const genUUID = () => {
        let uuid = uuidv4()
        return uuid
    }

    // DELETE
    const handleDeleteContact = (id: any) => {
        const updatedContacts = contactsWithId?.filter(contact => contact.id !== id);

        setContactsWithId(updatedContacts) // Updating the contacts with Ids state
        formik.setFieldValue("contacts", updatedContacts.map(({ id, ...rest }) => rest));  // Updating the main Formik state and removing ids
    }

    // EDIT
    const handleEditContact = (id: any) => {
        const contactToEdit = contactsWithId?.find(contact => contact.id === id);
        if (contactToEdit) {
            const { id, ...contactWithoutId } = contactToEdit;
            formikContact.setValues(contactWithoutId);
            setContactBeforeEdit(contactToEdit);
            handleDeleteContact(id);  // Remove the contact temporarily from the list
            setEditMode(true);
        } else {
            console.log("Contact not found with ID:", id);
        }
    };

    const handleEditContactSave = () => {
        const newContact = { ...formikContact.values, id: genUUID() };
        const updatedContacts = [...contactsWithId || [], newContact]; // Adding back edited contact

        setContactsWithId(updatedContacts) // Updating the contacts with Ids state
        formik.setFieldValue("contacts", updatedContacts.map(({ id, ...rest }) => rest));  // Updating the main Formik state and removing ids

        formikContact.resetForm();  // Reseting the secondary Formik form
        setEditMode(false);
    };

    // Cancel
    const handleEditContactCancel = () => {
        const oldContact = { ...contactBeforeEdit, id: genUUID() };
        const updatedContacts = [...contactsWithId || [], oldContact]; // Restoring back the original contact data 

        setContactsWithId(updatedContacts) // Updating the contacts with Ids state
        formik.setFieldValue("contacts", updatedContacts.map(({ id, ...rest }) => rest)); // Updating the main Formik state and removing ids

        formikContact.resetForm(); // Reset the secondary Formik form
        setContactBeforeEdit(undefined); // Reset the saved original contact data
        setEditMode(false);
    };

    // CREATE

    const handleAddContact = () => {
        formikContact.validateForm().then(errors => {
            if (Object.keys(errors).length === 0 && formikContact.isValid) {  // Ensures there are no validation errors
                const newContact: ContactWithId = { ...formikContact.values, id: genUUID() };
                const updatedContacts = [...contactsWithId || [], newContact];

                setContactsWithId(updatedContacts) // Updating the contacts with Ids state
                formik.setFieldValue("contacts", updatedContacts.map(({ id, ...rest }) => rest)); // Updating the main Formik state and removing ids

                formikContact.resetForm();  // Reset the secondary Formik form
            } else {
                // Making sure to touch all fields to show errors
                formikContact.setTouched({
                    contactName: true,
                    position: true,
                    address: true,
                    email: true,
                    city: true,
                    state: true,
                    zipCode: true,
                    phone: true,
                    preferredCommunication: true,
                    country: true
                });
            }
        });
    };

    useEffect(() => {
        // Because when tab switches, the state of this tab dies and when user comes back we need to put it in again
        const fetchData = async () => {
            const updatedContactsWithId = formik.values.contacts?.map(contact => ({
                ...contact,
                id: genUUID()
            }));

            if (updatedContactsWithId) {
                setContactsWithId(updatedContactsWithId);
            }
        };
        fetchData();
    }, []);

    return (
        <>
            <Typography color="primary">{tAddC("ADD ADDITIONAL CONTACTS")}</Typography>
            <Divider sx={{ marginBottom: 1 }} />
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <div className="grid w-full grid-flow-col grid-rows-6 gap-4 webkit-box">
                    <div className="flex justify-center">
                        <TextField variant="filled" {...textFieldProps} label={tAddC("Contact Name")} name="contactName"
                            error={Boolean(getFormikField(formikContact, "contactName").error)}
                            helperText={getFormikField(formikContact, "contactName").error}
                            value={getFormikField(formikContact, "contactName").value} onChange={formikContact.handleChange}
                        />
                    </div>
                    <div className="flex justify-center">
                        <TextField variant="filled" {...textFieldProps} label={tGC("Address")} name="address"
                            error={Boolean(getFormikField(formikContact, "address").error)}
                            helperText={getFormikField(formikContact, "address").error}
                            value={getFormikField(formikContact, "address").value} onChange={formikContact.handleChange}
                        />
                    </div>
                    <div className="flex justify-center">
                        <TextField variant="filled" {...textFieldProps} label={tGC("City")} name="city"
                            error={Boolean(getFormikField(formikContact, "city").error)}
                            helperText={getFormikField(formikContact, "city").error}
                            value={getFormikField(formikContact, "city").value} onChange={formikContact.handleChange}
                        />
                    </div>
                    <div className="flex justify-center">
                        <TextField variant="filled" {...textFieldProps} label={tGC("Zip")} name="zipCode"
                            error={Boolean(getFormikField(formikContact, "zipCode").error)}
                            helperText={getFormikField(formikContact, "zipCode").error}
                            value={getFormikField(formikContact, "zipCode").value} onChange={formikContact.handleChange}
                        />
                    </div>
                    <div className="flex justify-center">
                        <TextField variant="filled" {...textFieldProps} {...textFieldSelectProps} label={tGC("State")} select name="state"
                            error={Boolean(getFormikField(formikContact, "state").error)}
                            helperText={getFormikField(formikContact, "state").error}
                            value={getFormikField(formikContact, "state").value} onChange={formikContact.handleChange}
                        >
                            <MenuItem value=""><em>None</em></MenuItem>
                            {usaStates.map((state) => (
                                <MenuItem key={state.abbreviation} value={state.abbreviation}>
                                    {state.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                    <div className="flex justify-center">
                        <TextField disabled variant="filled" {...textFieldProps} {...textFieldSelectProps} label={tGC("Country")} select name="country"
                            error={Boolean(getFormikField(formikContact, "country").error)}
                            helperText={getFormikField(formikContact, "country").error}
                            value={getFormikField(formikContact, "country").value} onChange={formikContact.handleChange}
                        >
                            <MenuItem value="US">US</MenuItem>
                        </TextField>
                    </div>
                    <div className="flex justify-center">
                        <TextField variant="filled" {...textFieldProps} label={tAddC("Position")} name="position"
                            error={Boolean(getFormikField(formikContact, "position").error)}
                            helperText={getFormikField(formikContact, "position").error}
                            value={getFormikField(formikContact, "position").value} onChange={formikContact.handleChange}
                        />
                    </div>
                    <div className="flex justify-center">
                        <TextField variant="filled" {...textFieldProps} label={tGC("Tel. No.")} name="phone"
                            error={Boolean(getFormikField(formikContact, "phone").error)}
                            helperText={getFormikField(formikContact, "phone").error}
                            value={getFormikField(formikContact, "phone").value} onChange={formikContact.handleChange}
                        />
                    </div>
                    <div className="flex justify-center">
                        <TextField variant="filled" {...textFieldProps} label={tGC("Email")} name="email"
                            error={Boolean(getFormikField(formikContact, "email").error)}
                            helperText={getFormikField(formikContact, "email").error}
                            value={getFormikField(formikContact, "email").value} onChange={formikContact.handleChange}
                        />
                    </div>
                    <div className="flex justify-center">
                        <TextField variant="filled" {...textFieldProps} label={tGC("Preferred Method of Communication")} name="preferredCommunication"
                            error={Boolean(getFormikField(formikContact, "preferredCommunication").error)}
                            helperText={getFormikField(formikContact, "preferredCommunication").error}
                            value={getFormikField(formikContact, "preferredCommunication").value} onChange={formikContact.handleChange}
                        />
                    </div>
                </div>
                <Box sx={{ paddingX: 5 }}>
                    <Box sx={{ paddingY: 1, paddingX: 1, display: "flex", justifyContent: "space-between" }}>
                        {editMode ? (
                            <>
                                <Tooltip title={tGlobal("Save")}>
                                    <IconButton size="small" sx={{ p: 0, marginRight: 2 }} color="primary" onClick={handleEditContactSave}>
                                        <SaveIcon cursor={"pointer"} fontSize={"large"} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={tGlobal("Cancel")}>
                                    <IconButton size="small" sx={{ p: 0 }} onClick={handleEditContactCancel}>
                                        <CancelIcon cursor={"pointer"} fontSize={"large"} />
                                    </IconButton>
                                </Tooltip></>
                        ) : (
                            <Tooltip sx={{ paddingX: 5 }} title={tGlobal("Add Contact")} placement="bottom">
                                <AddCircleIcon onClick={handleAddContact} cursor={"pointer"} fontSize={"large"} />
                            </Tooltip>
                        )}
                    </Box>
                </Box>

            </Box >
            <Typography color="primary" sx={{ marginTop: 2 }} >{tAddC("CONTACTS")}</Typography>
            <Divider sx={{ marginBottom: 1 }} />
            <div style={{ height: 200, width: "100%" }}>
                <DataGridPro
                    sx={{
                        border: 0, // Removes all borders
                        "& .MuiDataGrid-cell": {
                            padding: columnPadding, // Adjust the padding as needed
                        },
                        "& .MuiDataGrid-columnHeader": {
                            padding: columnPadding, // Adjust the padding as needed
                        }
                    }}
                    hideFooter
                    disableColumnSorting
                    disableColumnFilter
                    rows={contactsWithId}
                    columns={columns}
                    pageSizeOptions={[contactsWithId?.length || 0]} // Make sure this number is equal to or greater than the number of rows to display all at once
                />
            </div>
            <Divider sx={{ marginY: 3 }} />
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <TextField
                    variant="filled"
                    label={tAddC("Notes")}
                    multiline
                    rows={4}
                    fullWidth
                    sx={{
                        backgroundColor: hexToRGBA(theme.palette.primary.main, 0.08),
                    }}
                    name="notes"
                    error={Boolean(getFormikField(formik, "notes").error)}
                    helperText={getFormikField(formik, "notes").error}
                    value={getFormikField(formik, "notes").value} onChange={formik.handleChange}
                />
            </Box>
        </>
    );
}

import { useAuth } from "../util";
import { CircularProgress, Stack } from "@mui/material"


export default function AuthProvider({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  const user = useAuth()
  if (user === "loading") {
    return (
      <Stack direction="column" justifyContent="center" alignItems="center" height="100%">
        <CircularProgress size={100} />
      </Stack>
    )
  }
  return <>{children}</>
}

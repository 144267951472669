import "immer"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface SettingsModalState {
  openSettingsModal: boolean
  loading: boolean
}

// Initialize state
const initialState: SettingsModalState = {
  openSettingsModal: false,
  loading: false
}

export const settingsModalSlice = createSlice({
  name: "settingsModal",
  initialState,
  reducers: {
    openSettingsModal: (state) => ({
      ...state,
      openSettingsModal: true,
    }),
    closeModal: () => ({
      ...initialState
    }),
    setLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      loading: action.payload,
    }),
  },
})

export const {
  openSettingsModal,
  closeModal,
  setLoading
} = settingsModalSlice.actions
export default settingsModalSlice.reducer

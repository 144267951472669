import "immer"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface ShipMethodsModalState {
    openShipMethodsModal: boolean
    loading: boolean
}

// Initialize state
const initialState: ShipMethodsModalState = {
    openShipMethodsModal: false,
    loading: false
}

export const shipMethodsModalSlice = createSlice({
    name: "shipMethodsModal",
    initialState,
    reducers: {
        openShipMethodsModal: (state) => ({
            ...state,
            openShipMethodsModal: true,
        }),
        closeModal: () => ({
            ...initialState
        }),
        setLoading: (state, action: PayloadAction<boolean>) => ({
            ...state,
            loading: action.payload,
        }),
    },
})

export const {
    openShipMethodsModal,
    closeModal,
    setLoading
} = shipMethodsModalSlice.actions
export default shipMethodsModalSlice.reducer

import { useEffect, useState } from "react";
import { Box, Button, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, Typography, Divider, TextField, Tooltip, Radio } from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useFormik } from "formik";
// TODO update input when api is ready
import type { AltShipMethods, AltShipMethodsCreateInput, AltShipMethodsUpdateInput } from "../../../../../../packages/orderflow-lambdas/src/shared/api/models/altShipMethods";
import { useTranslation } from "react-i18next";
import { buttonWidth, textFieldProps } from "../constants/form";
import { pushToast, useDispatch, useSelector } from "../../../state";
import { closeModal, setLoading } from "../../../state/altShipMethodsModalSlice";
import { getFormikField } from "../../../util/fornik-helpers";
import { altShipMethodsSchema } from "./schemas/AltShipMethodsSchema";
import { TRPCClientError } from "@trpc/client";
import { serverError } from "../constants/errors";
import { client } from "../../../util";

const columnPadding = "0 2px"


const initialValues: AltShipMethodsCreateInput = {
    name: "",
    contact: "",
};

export default function AltShipMethodsModal() {
    const { t: tGlobal } = useTranslation([], { keyPrefix: "team.Global.Buttons" })

    const dispatch = useDispatch();

    const { openAltShipMethodsModal, loading } = useSelector(({ altShipMethodsModalSlice }) => altShipMethodsModalSlice);

    const [altShipMethods, setAltShipMethods] = useState<AltShipMethods[]>([]);
    const [altShipMethodsBeforeEdit, setAltShipMethodsBeforeEdit] = useState<AltShipMethods>();

    // edit Mode
    const [editMode, setEditMode] = useState<boolean>(false);

    const resetState = () => {
        formik.resetForm()
    };

    const handleClose = () => {
        dispatch(closeModal());
        resetState();
    };

    const handleAddAltShipMethods = async (payload: AltShipMethodsCreateInput) => {
        dispatch(setLoading(true))
        try {
            const modifiedPayload = {
                ...payload,
            };

            // TODO connect api when ready
            // const paymentTerm = await client.createAltShipMethod.mutate(modifiedPayload);
            // setAltShipMethods([...altShipMethods, paymentTerm]);
            formik.resetForm();
            dispatch(pushToast({ message: "Term created successfully!", type: "success" }));
        } catch (error) {
            if (error instanceof TRPCClientError) {
                dispatch(pushToast({ message: error.message, type: "error" }));
            } else {
                dispatch(pushToast({ message: serverError, type: "error" }));
            }
        }
        dispatch(setLoading(false))
    }

    // Formik initialization
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: altShipMethodsSchema,
        onSubmit: handleAddAltShipMethods,
    });

    const columns: GridColDef[] = [
        { field: "name", headerName: "Method*", flex: 1 },
        { field: "contact", headerName: "Contact", flex: 1 },
        {
            field: "actions",
            headerName: tGlobal("Actions"),
            width: 80,
            flex: 0.5,
            renderCell: (params) => {
                const handleEdit = () => {
                    handleEditAltShipMethod(params.id)
                };

                const handleDelete = () => {
                    handleDeleteAltShipMethod(params.id)
                };

                return (
                    <div>
                        <IconButton onClick={handleEdit}>
                            <EditIcon />
                        </IconButton>
                        <IconButton onClick={handleDelete}>
                            <DeleteIcon />
                        </IconButton>
                    </div>
                );
            },
        }
    ];


    const handleDeleteAltShipMethod = async (id: any) => {
        dispatch(setLoading(true))
        try {
            // TODO connect api when ready
            // await client.deleteAltShipMethod.mutate(id);
            // Updating the altShipMethods state by filtering out the deleted tax code
            setAltShipMethods((prevAltShipMethods) =>
                prevAltShipMethods.filter((paymentTerm) => paymentTerm.AltShipMethodId !== id)
            );
        } catch (error) {
            if (error instanceof TRPCClientError) {
                dispatch(pushToast({ message: error.message, type: "error" }));
            } else {
                dispatch(pushToast({ message: serverError, type: "error" }));
            }
        }
        dispatch(setLoading(false))
    }

    const handleEditAltShipMethod = (id: any) => {
        const selectedAltShipMethod = altShipMethods.find((paymentTerm) => paymentTerm.AltShipMethodId === id);
        if (selectedAltShipMethod) {
            formik.setFieldValue("name", selectedAltShipMethod.name);
            formik.setFieldValue("contact", selectedAltShipMethod.contact);
            setAltShipMethodsBeforeEdit(selectedAltShipMethod)
            setEditMode(true)
        } else {
            console.log("TaxCode not found with ID:", id);
        }
    };

    const handleEditAltShipMethodSave = async () => {
        dispatch(setLoading(true))
        try {
            if (altShipMethodsBeforeEdit) {
                const modifiedPayload: AltShipMethodsUpdateInput = {
                    ...formik.values,
                    AltShipMethodId: altShipMethodsBeforeEdit.AltShipMethodId,
                };
                // TODO connect api when ready
                // const updatedAltShipMethods = await client.updateAltShipMethod.mutate(modifiedPayload);
                // setAltShipMethods((prevAltShipMethods) =>
                //     prevAltShipMethods.map((altShipMethods) =>
                //         altShipMethods.AltShipMethodId === updatedAltShipMethods.AltShipMethodId ? updatedAltShipMethods : altShipMethods
                //     )
                // );

                formik.resetForm(); // Reset the secondary Formik form
                setAltShipMethodsBeforeEdit(undefined); // Reset the saved original contact data
                setEditMode(false);
                dispatch(pushToast({ message: "Term saved successfully!", type: "success" }));
            } else {
                // this should never happened. Before edit save user should always select AltShipMethod that wants to edit
                console.log("AltShipMethods for edit was not selected.");
            }
        } catch (error) {
            if (error instanceof TRPCClientError) {
                dispatch(pushToast({ message: error.message, type: "error" }));
            } else {
                dispatch(pushToast({ message: serverError, type: "error" }));
            }
        }
        dispatch(setLoading(false))
    };

    const handleEditAltShipMethodCancel = async () => {
        formik.resetForm();
        setEditMode(false)
    };

    useEffect(() => {
        const fetchData = async () => {
            dispatch(setLoading(true))
            try {
                // TODO connect api when ready
                // const resp = await client.allAltShipMethods.query();
                // setAltShipMethods(resp.results)
            } catch (error) {
                if (error instanceof TRPCClientError) {
                    dispatch(pushToast({ message: error.message, type: "error" }));
                } else {
                    dispatch(pushToast({ message: serverError, type: "error" }));
                }
            }
            dispatch(setLoading(false))
        };
        fetchData();
    }, []);

    return (
        <Dialog
            open={openAltShipMethodsModal}
            onClose={(event,reason)=>{
                if (reason && reason === "backdropClick") 
                    return;
                handleClose()
            }}
            maxWidth="lg"
            scroll="paper"
            PaperProps={{ className: "w-full" }}
        >
            <DialogTitle sx={{ m: 0, p: 1 }}>{"Alternate Ship"}</DialogTitle  >
            <IconButton disabled={loading} color="primary" sx={{ position: "absolute", right: 4, top: 4 }} onClick={handleClose}>
                <CloseIcon />
            </IconButton>
            <DialogContent dividers sx={{ p: 3 }} >
                {loading ? (
                    <Box height="400px" display="flex" justifyContent="center" alignItems="center">
                        <CircularProgress size={80} />
                    </Box>
                ) : (
                    <>
                        <Box sx={{ display: "flex", justifyContent: "start" }}>
                            <div className="grid grid-flow-col grid-rows-1 gap-4 md:w-3/6 webkit-box">
                                <TextField variant="filled" {...textFieldProps} label={"Method"} name="name"
                                    error={Boolean(getFormikField(formik, "name").error)}
                                    helperText={getFormikField(formik, "name").error}
                                    value={getFormikField(formik, "name").value} onChange={formik.handleChange}
                                />
                                <TextField variant="filled" {...textFieldProps} label={"Contact"} name="contact"
                                    error={Boolean(getFormikField(formik, "contact").error)}
                                    helperText={getFormikField(formik, "contact").error}
                                    value={getFormikField(formik, "contact").value} onChange={formik.handleChange}
                                />
                            </div>
                            <Box sx={{ paddingX: 2 }}>
                                <Box sx={{ paddingY: 1, paddingX: 1, display: "flex", justifyContent: "space-between" }}>
                                    {editMode ? (
                                        <>
                                            <Tooltip title={tGlobal("Save")}>
                                                <IconButton size="small" sx={{ p: 0, marginRight: 2 }} color="primary" onClick={handleEditAltShipMethodSave}>
                                                    <SaveIcon cursor={"pointer"} fontSize={"large"} />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title={tGlobal("Cancel")}>
                                                <IconButton size="small" sx={{ p: 0 }} onClick={handleEditAltShipMethodCancel}>
                                                    <CancelIcon cursor={"pointer"} fontSize={"large"} />
                                                </IconButton>
                                            </Tooltip></>
                                    ) : (
                                        <Tooltip sx={{ paddingX: 5 }} title={tGlobal("Add Alternate Ship")} placement="bottom">
                                            <AddCircleIcon onClick={() => { formik.submitForm() }} cursor={"pointer"} fontSize={"large"} />
                                        </Tooltip>
                                    )}
                                </Box>
                            </Box>
                        </Box >
                        <Typography color="primary" sx={{ marginTop: 2 }} >{"Terms"}</Typography>
                        <Divider />
                        <div style={{ height: 400, width: "100%" }}>
                            <DataGridPro
                                sx={{
                                    border: 0, // Removes all borders
                                    "& .MuiDataGrid-cell": {
                                        padding: columnPadding, // Adjust the padding as needed
                                    },
                                    "& .MuiDataGrid-columnHeader": {
                                        padding: columnPadding, // Adjust the padding as needed
                                    },
                                    ".MuiDataGrid-columnHeaderTitle": { fontWeight: "bold" }
                                }}
                                hideFooter
                                disableColumnSorting
                                disableColumnFilter
                                rows={altShipMethods && altShipMethods.map((paymentTerm) => {
                                    return {
                                        id: paymentTerm.AltShipMethodId,
                                        name: paymentTerm.name,
                                        contact: paymentTerm.contact
                                    }
                                })}
                                columns={columns}
                                pageSizeOptions={[altShipMethods?.length || 0]} // Make sure this number is equal to or greater than the number of rows to display all at once
                            />
                        </div>
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Box width="100%" sx={{ display: "flex", justifyContent: "end" }}>
                    <Button disabled={loading} variant="outlined" color="primary" sx={{ width: buttonWidth }} onClick={handleClose}>{tGlobal("Cancel")}</Button>
                </Box>
            </DialogActions>
        </Dialog >
    );
}

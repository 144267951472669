import { useEffect, useState } from "react";
import { Box, Button, IconButton, InputAdornment, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, Typography, Divider, TextField, Tooltip, Radio } from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useFormik } from "formik";
import type { PaymentTerms, PaymentTermsCreateInput, PaymentTermsUpdateInput } from "orderflow-lambdas";
import { useTranslation } from "react-i18next";
import { buttonWidth, textFieldProps } from "../constants/form";
import { pushToast, useDispatch, useSelector } from "../../../state";
import { closeModal, setLoading } from "../../../state/customerTermsModalSlice";
import { getFormikField } from "../../../util/fornik-helpers";
import { paymentTermsSchema } from "./schemas/PaymentTermsSchema";
import { TRPCClientError } from "@trpc/client";
import { serverError } from "../constants/errors";
import { client } from "../../../util";

const columnPadding = "0 2px"

const initialValues: PaymentTermsCreateInput = {
    name: "",
    description:"", 
    days: 0,
    weight: 0
};

export default function CustomerTermsModal() {
    const { t: tGC } = useTranslation([], { keyPrefix: "team.Global.Common" })
    const { t: tGlobal } = useTranslation([], { keyPrefix: "team.Global.Buttons" })
    const { t: tPLB } = useTranslation([], { keyPrefix: "team.PageLayout.LB" })
    const { t: tMC } = useTranslation([], { keyPrefix: "team.Modals.CustomerTerms" })

    const dispatch = useDispatch();

    const { openCustomerTermsModal, loading } = useSelector(({ customerTermsModalSlice }) => customerTermsModalSlice);

    const [paymentTerms, setPaymentTerms] = useState<PaymentTerms[]>([]);
    const [paymentTermsBeforeEdit, setPaymentTermsBeforeEdit] = useState<PaymentTerms>();

    // edit Mode
    const [editMode, setEditMode] = useState<boolean>(false);

    const resetState = () => {
        formik.resetForm()
    };

    const handleClose = () => {
        dispatch(closeModal());
        resetState();
    };

    const handleAddCustomerTerms = async (payload: PaymentTermsCreateInput) => {
        dispatch(setLoading(true))
        try {
            const modifiedPayload = {
                ...payload,
                days: Number(payload.days), // Explicitly convert to number
                weight: Number(payload.weight) / 100, // Explicitly convert to number
            };

            const paymentTerm = await client.createPaymentTerm.mutate(modifiedPayload);
            setPaymentTerms([...paymentTerms, paymentTerm]);
            formik.resetForm();
            dispatch(pushToast({ message: tMC("Term created successfully!"), type: "success" }));
        } catch (error) {
            if (error instanceof TRPCClientError) {
                dispatch(pushToast({ message: error.message, type: "error" }));
            } else {
                dispatch(pushToast({ message: serverError, type: "error" }));
            }
        }
        dispatch(setLoading(false))
    }

    // Formik initialization
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: paymentTermsSchema,
        onSubmit: handleAddCustomerTerms,
    });

    const columns: GridColDef[] = [
        { field: "name", headerName: tMC("Terms Code") + "*", flex: 1 },
        { field: "description", headerName: tMC("Terms Description"), flex: 1 },
        {
            field: "default",
            headerName: tGC("Default"),
            flex: 0.5,
            renderCell: (params) => {
                const selectedPaymentTerm = paymentTerms.find((paymentTerm) => paymentTerm.PaymentTermId === params.id);

                if (selectedPaymentTerm) {
                    const handleCheckDefault = () => {
                        handleChangeDefault(selectedPaymentTerm)
                    };

                    return (
                        <div>
                            <Radio checked={selectedPaymentTerm.default} onClick={handleCheckDefault} />
                        </div>
                    )
                } else {
                    // Should never happened but good to have just in case.
                    console.log("Cant find PaymentTerm on table render with id: ", params.id)
                }

            },
        },
        {
            field: "actions",
            headerName: tGlobal("Actions"),
            width: 80,
            flex: 0.5,
            renderCell: (params) => {
                const handleEdit = () => {
                    handleEditPaymentTerm(params.id)
                };

                const handleDelete = () => {
                    handleDeletePaymentTerm(params.id)
                };

                return (
                    <div>
                        <IconButton onClick={handleEdit}>
                            <EditIcon />
                        </IconButton>
                        <IconButton onClick={handleDelete}>
                            <DeleteIcon />
                        </IconButton>
                    </div>
                );
            },
        }
    ];

    const handleChangeDefault = async (newDefaultPaymentTerm: PaymentTerms) => {
        dispatch(setLoading(true))
        try {
            const resp = await client.setDefaultPaymentTerm.mutate(newDefaultPaymentTerm.PaymentTermId);
            const defaultPT = paymentTerms.find((paymentTerm) => paymentTerm.default === true);
            if (defaultPT) {
                defaultPT.default = false
                setPaymentTerms((prevPaymentTerms) =>
                    prevPaymentTerms.map((paymentTerms) =>
                        paymentTerms.PaymentTermId === defaultPT.PaymentTermId ? defaultPT : paymentTerms
                    )
                );
            }
            newDefaultPaymentTerm.default = true
            setPaymentTerms((prevPaymentTerms) =>
                prevPaymentTerms.map((paymentTerms) =>
                    paymentTerms.PaymentTermId === newDefaultPaymentTerm.PaymentTermId ? newDefaultPaymentTerm : paymentTerms
                )
            );
        } catch (error) {
            if (error instanceof TRPCClientError) {
                dispatch(pushToast({ message: error.message, type: "error" }));
            } else {
                dispatch(pushToast({ message: serverError, type: "error" }));
            }
        }
        dispatch(setLoading(false))
    }

    const handleDeletePaymentTerm = async (id: any) => {
        dispatch(setLoading(true))
        try {
            await client.deletePaymentTerm.mutate(id);
            // Updating the paymentTerms state by filtering out the deleted tax code
            setPaymentTerms((prevPaymentTerms) =>
                prevPaymentTerms.filter((paymentTerm) => paymentTerm.PaymentTermId !== id)
            );
        } catch (error) {
            if (error instanceof TRPCClientError) {
                dispatch(pushToast({ message: error.message, type: "error" }));
            } else {
                dispatch(pushToast({ message: serverError, type: "error" }));
            }
        }
        dispatch(setLoading(false))
    }

    const handleEditPaymentTerm = (id: any) => {
        const selectedPaymentTerm = paymentTerms.find((paymentTerm) => paymentTerm.PaymentTermId === id);
        if (selectedPaymentTerm) {
            formik.setFieldValue("name", selectedPaymentTerm.name);
            formik.setFieldValue("days", selectedPaymentTerm.days);
            formik.setFieldValue("weight", selectedPaymentTerm.weight * 100);
            setPaymentTermsBeforeEdit(selectedPaymentTerm)
            setEditMode(true)
        } else {
            console.log("TaxCode not found with ID:", id);
        }
    };

    const handleEditPaymentTermSave = async () => {
        dispatch(setLoading(true))
        try {
            if (paymentTermsBeforeEdit) {
                const modifiedPayload: PaymentTermsUpdateInput = {
                    ...formik.values,
                    days: Number(formik.values.days), // Explicitly convert to number
                    weight: Number(formik.values.weight) / 100, // Explicitly convert to number
                    PaymentTermId: paymentTermsBeforeEdit.PaymentTermId,
                };
                const updatedPaymentTerms = await client.updatePaymentTerm.mutate(modifiedPayload);
                setPaymentTerms((prevPaymentTerms) =>
                    prevPaymentTerms.map((paymentTerms) =>
                        paymentTerms.PaymentTermId === updatedPaymentTerms.PaymentTermId ? updatedPaymentTerms : paymentTerms
                    )
                );

                formik.resetForm(); // Reset the secondary Formik form
                setPaymentTermsBeforeEdit(undefined); // Reset the saved original contact data
                setEditMode(false);
                dispatch(pushToast({ message: tMC("Term saved successfully!"), type: "success" }));
            } else {
                // this should never happened. Before edit save user should always select PaymentTerm that wants to edit
                console.log("PaymentTerms for edit was not selected.");
            }
        } catch (error) {
            if (error instanceof TRPCClientError) {
                dispatch(pushToast({ message: error.message, type: "error" }));
            } else {
                dispatch(pushToast({ message: serverError, type: "error" }));
            }
        }
        dispatch(setLoading(false))
    };

    const handleEditPaymentTermCancel = async () => {
        formik.resetForm();
        setEditMode(false)
    };

    useEffect(() => {
        if (!openCustomerTermsModal) return;
        const fetchData = async () => {
            dispatch(setLoading(true))
            try {
                const resp = await client.allPaymentTerms.query();
                setPaymentTerms(resp.results)
            } catch (error) {
                if (error instanceof TRPCClientError) {
                    dispatch(pushToast({ message: error.message, type: "error" }));
                } else {
                    dispatch(pushToast({ message: serverError, type: "error" }));
                }
            }
            dispatch(setLoading(false))
        };
        fetchData();
    }, [openCustomerTermsModal]);

    return (
        <Dialog
            open={openCustomerTermsModal}
            onClose={(event, reason) => {
                if (reason && reason === "backdropClick")
                    return;
                handleClose()
            }}
            maxWidth="lg"
            scroll="paper"
            PaperProps={{ className: "w-full" }}
        >
            <DialogTitle sx={{ m: 0, p: 1 }}>{tPLB("Menu.Utilities.Customer Terms")}</DialogTitle  >
            <IconButton disabled={loading} color="primary" sx={{ position: "absolute", right: 4, top: 4 }} onClick={handleClose}>
                <CloseIcon />
            </IconButton>
            <DialogContent dividers sx={{ p: 3 }} >
                {loading ? (
                    <Box height="400px" display="flex" justifyContent="center" alignItems="center">
                        <CircularProgress size={80} />
                    </Box>
                ) : (
                    <>
                        <Box sx={{ display: "flex", justifyContent: "start" }}>
                            <div className="grid grid-flow-col grid-rows-2 gap-4 md:w-3/6 webkit-box">
                                <TextField variant="filled" {...textFieldProps} label={tMC("Terms Code")} name="name"
                                    error={Boolean(getFormikField(formik, "name").error)}
                                    helperText={getFormikField(formik, "name").error}
                                    value={getFormikField(formik, "name").value} onChange={formik.handleChange}
                                />
                                <TextField variant="filled" {...textFieldProps} label={tMC("Terms Description")} name="description"
                                error={Boolean(getFormikField(formik, "description").error)}
                                helperText={getFormikField(formik, "description").error}
                                value={getFormikField(formik, "description").value} onChange={formik.handleChange}
                                />
                                <TextField variant="filled" {...textFieldProps} label={tGC("Days")} name="days" type="number"
                                    error={Boolean(getFormikField(formik, "days").error)}
                                    helperText={getFormikField(formik, "days").error}
                                    value={getFormikField(formik, "days").value} onChange={formik.handleChange}
                                />
                                <TextField variant="filled" {...textFieldProps} label={tGC("Weight")} name="weight" type="number"
                                    error={Boolean(getFormikField(formik, "weight").error)}
                                    helperText={getFormikField(formik, "weight").error}
                                    value={getFormikField(formik, "weight").value} onChange={formik.handleChange}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                %
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </div>
                            <Box sx={{ paddingX: 2 }}>
                                <Box sx={{ paddingY: 1, paddingX: 1, display: "flex", justifyContent: "space-between" }}>
                                    {editMode ? (
                                        <>
                                            <Tooltip title={tGlobal("Save")}>
                                                <IconButton size="small" sx={{ p: 0, marginRight: 2 }} color="primary" onClick={handleEditPaymentTermSave}>
                                                    <SaveIcon cursor={"pointer"} fontSize={"large"} />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title={tGlobal("Cancel")}>
                                                <IconButton size="small" sx={{ p: 0 }} onClick={handleEditPaymentTermCancel}>
                                                    <CancelIcon cursor={"pointer"} fontSize={"large"} />
                                                </IconButton>
                                            </Tooltip></>
                                    ) : (
                                        <Tooltip sx={{ paddingX: 5 }} title={tGlobal("Add Customer Term")} placement="bottom">
                                            <AddCircleIcon onClick={() => { formik.submitForm() }} cursor={"pointer"} fontSize={"large"} />
                                        </Tooltip>
                                    )}
                                </Box>
                            </Box>
                        </Box >
                        <Typography color="primary" sx={{ marginTop: 2 }} >{tGC("Terms")}</Typography>
                        <Divider />
                        <div style={{ height: 400, width: "100%" }}>
                            <DataGridPro
                                sx={{
                                    border: 0, // Removes all borders
                                    "& .MuiDataGrid-cell": {
                                        padding: columnPadding, // Adjust the padding as needed
                                    },
                                    "& .MuiDataGrid-columnHeader": {
                                        padding: columnPadding, // Adjust the padding as needed
                                    },
                                    ".MuiDataGrid-columnHeaderTitle": { fontWeight: "bold" }
                                }}
                                hideFooter
                                disableColumnSorting
                                disableColumnFilter
                                rows={paymentTerms && paymentTerms.map((paymentTerm) => {
                                    return {
                                        id: paymentTerm.PaymentTermId,
                                        name: paymentTerm.name,
                                        default: paymentTerm.default
                                        // description: paymentTerm.description,
                                    }
                                })}
                                columns={columns}
                                pageSizeOptions={[paymentTerms?.length || 0]} // Make sure this number is equal to or greater than the number of rows to display all at once
                            />
                        </div>
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Box width="100%" sx={{ display: "flex", justifyContent: "end" }}>
                    <Button disabled={loading} variant="outlined" color="primary" sx={{ width: buttonWidth }} onClick={handleClose}>{tGlobal("Cancel")}</Button>
                </Box>
            </DialogActions>
        </Dialog >
    );
}

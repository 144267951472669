import * as React from 'react';
import {Menu, MenuItem, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import PersonIcon from '@mui/icons-material/Person';
import HistoryIcon from '@mui/icons-material/History';
import DeleteIcon from '@mui/icons-material/Delete';
import ListIcon from '@mui/icons-material/List';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useDispatch } from '../../state';
import { openDeleteVendorModal, openEditVendorModal } from "../../state/vendorModalSlice";
import { Vendor } from 'orderflow-lambdas';

interface BasicMenuProps {
  vendor: Vendor
}

export default function BasicMenu(props: BasicMenuProps) {
  // const {searchInput, searchBy} = useSelector((store) => store.vendorBrowseSlice)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);

  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditVendor = () => {
    dispatch(openEditVendorModal({
      vendorIdToEdit: props.vendor.VendorId,
      updateCallback: async (vendor) => {
        if (vendor) {
          // Here you can handle updates with props.vendor and user
        }
      },
    }));
    setAnchorEl(null);
  };

  const handleDeleteVendor = () => {
    setAnchorEl(null);
    dispatch(openDeleteVendorModal({
      vendorToDelete: props.vendor,
      // it takes too long for changes to propogate after deleting a vendor

      // deleteCallback: async () => {
      //   const payload:VendorSearchInput = { query: searchInput, searchBy, size: 100, page: 0}
      //   dispatch(searchVendors(payload))
      // }
    }))
  }

  const OptionsMUIIconSize = 30;

  return (
    <div>
      <IconButton
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className='text-2xl tracking-tight border-0 rounded-full'
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleEditVendor} style={{ color: 'rgb(51, 99, 255)' }}><EditIcon style={{ fontSize: OptionsMUIIconSize }} />&nbsp; Edit Vendor</MenuItem>
        <MenuItem onClick={handleClose} style={{ color: 'rgb(51, 99, 255)' }}><ListIcon style={{ fontSize: OptionsMUIIconSize }} />&nbsp; View Order History</MenuItem>
        <MenuItem onClick={handleClose} style={{ color: 'rgb(51, 99, 255)' }}><PersonIcon style={{ fontSize: OptionsMUIIconSize }} />&nbsp; View Vendor</MenuItem>
        <MenuItem onClick={handleClose} style={{ color: 'rgb(51, 99, 255)' }}><HistoryIcon style={{ fontSize: OptionsMUIIconSize }} />&nbsp; View Timeline</MenuItem>
        <MenuItem onClick={handleDeleteVendor} style={{ color: 'rgb(51, 99, 255)' }}><DeleteIcon style={{ fontSize: OptionsMUIIconSize }} />&nbsp; Delete</MenuItem>
      </Menu>
    </div>
  );
}
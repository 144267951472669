import React, { Suspense } from 'react';
import { Paper } from "@mui/material";
import styles from "./index.module.css"
import SendChallenge from "./SendChallenge";
import logo from "../../images/logo-black.png"

export default function Page(): JSX.Element {

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <section className={styles.main}>
        <Paper className={styles.wrapper} elevation={3}>
          <img
            src={logo}
            alt="logo"
            className={styles.logo}
            // width={logo.width}
            // height={logo.height}
          />
          <SendChallenge />
        </Paper>
      </section>
    </Suspense>
  );
}

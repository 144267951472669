import React, { CSSProperties } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faIndustry } from '@fortawesome/free-solid-svg-icons';


interface VendorSlipIconProps {
    onClick?: () => void;
    className?: string;
    style?: React.CSSProperties;
}

const iconStyle: CSSProperties = {
    position: 'relative',
    paddingLeft: '5px',
};

const iconPlusStyle: CSSProperties = {
    position: "absolute",
    top: "8px",
    left: "-5px",
    fontSize: "10px",
};

const VendorSlipIcon: React.FC<VendorSlipIconProps> = ({
    onClick,
    className = "",
    style = {},
}) => (
    <div style={{ ...iconStyle, ...style }} className={`${className}`} onClick={onClick}>
        <FontAwesomeIcon icon={faIndustry} />
        <FontAwesomeIcon icon={faPlus} style={iconPlusStyle} />
    </div>
);

export default VendorSlipIcon;

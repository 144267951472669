import { Box, TextField, FormControlLabel, Checkbox } from "@mui/material";
import type { OrderflowSettings } from "orderflow-lambdas";
import { textFieldProps, wideTextFieldProps } from "../constants/form";
import { FormikProps } from "formik";
import { getFormikField } from "../../../util/fornik-helpers";
import dayjs, { Dayjs } from "dayjs";

export default function AcknowledgmentsTab({
    settingsFormik,
}: {
    settingsFormik: FormikProps<OrderflowSettings>;
}): JSX.Element {

    return (
        <>
            <div className="grid grid-flow-col grid-rows-5 gap-4 webkit-box">
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={"Sold to"} name="acknowledgements.soldTo"
                        error={Boolean(getFormikField(settingsFormik, "acknowledgements.soldTo").error)}
                        helperText={getFormikField(settingsFormik, "acknowledgements.soldTo").error}
                        value={getFormikField(settingsFormik, "acknowledgements.soldTo").value} onChange={settingsFormik.handleChange}
                    />
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={"Ship to"} name="acknowledgements.shipTo"
                        error={Boolean(getFormikField(settingsFormik, "acknowledgements.shipTo").error)}
                        helperText={getFormikField(settingsFormik, "acknowledgements.shipTo").error}
                        value={getFormikField(settingsFormik, "acknowledgements.shipTo").value} onChange={settingsFormik.handleChange}
                    />
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={"Approximate Ship Date"} name="acknowledgements.approximateShipDate"
                        error={Boolean(getFormikField(settingsFormik, "acknowledgements.approximateShipDate").error)}
                        helperText={getFormikField(settingsFormik, "acknowledgements.approximateShipDate").error}
                        value={getFormikField(settingsFormik, "acknowledgements.approximateShipDate").value} onChange={settingsFormik.handleChange}
                    />
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={"Deadline Use"} name="acknowledgements.deadlineUse"
                        error={Boolean(getFormikField(settingsFormik, "acknowledgements.deadlineUse").error)}
                        helperText={getFormikField(settingsFormik, "acknowledgements.deadlineUse").error}
                        value={getFormikField(settingsFormik, "acknowledgements.deadlineUse").value} onChange={settingsFormik.handleChange}
                    />
                </div>
                <Box {...textFieldProps} sx={{ display: "flex", justifyContent: "center" }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                value={Boolean(getFormikField(settingsFormik, "acknowledgements.showCustomerTerms").value)}
                            />
                        }
                        label={"Show Customer Terms"} name="acknowledgements.showCustomerTerms"
                        onChange={settingsFormik.handleChange}
                    />
                </Box>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={"Acknowledgment"} name="acknowledgements.acknowledgement"
                        error={Boolean(getFormikField(settingsFormik, "acknowledgements.acknowledgement").error)}
                        helperText={getFormikField(settingsFormik, "acknowledgements.acknowledgement").error}
                        value={getFormikField(settingsFormik, "acknowledgements.acknowledgement").value} onChange={settingsFormik.handleChange}
                    />
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={"Quantity"} name="acknowledgements.quantity"
                        error={Boolean(getFormikField(settingsFormik, "acknowledgements.quantity").error)}
                        helperText={getFormikField(settingsFormik, "acknowledgements.quantity").error}
                        value={getFormikField(settingsFormik, "acknowledgements.quantity").value} onChange={settingsFormik.handleChange}
                    />
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={"Ship Via"} name="acknowledgements.shipVia"
                        error={Boolean(getFormikField(settingsFormik, "acknowledgements.shipVia").error)}
                        helperText={getFormikField(settingsFormik, "acknowledgements.shipVia").error}
                        value={getFormikField(settingsFormik, "acknowledgements.shipVia").value} onChange={settingsFormik.handleChange}
                    />
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...textFieldProps} label={"Default Ship"} name="acknowledgements.defaultShip"
                        error={Boolean(getFormikField(settingsFormik, "acknowledgements.defaultShip").error)}
                        helperText={getFormikField(settingsFormik, "acknowledgements.defaultShip").error}
                        value={getFormikField(settingsFormik, "acknowledgements.defaultShip").value} onChange={settingsFormik.handleChange}
                    />
                </div>
            </div>
            <div className="grid grid-flow-col grid-rows-4 gap-4 pt-8 webkit-box">
                <div className="flex justify-center w-full">
                    <FormControlLabel
                        {...wideTextFieldProps}
                        className="mr-0"
                        control={
                            <Checkbox
                                value={Boolean(getFormikField(settingsFormik, "acknowledgements.enableInstructionLine").value)}
                            />
                        }
                        label={"Enable"} name="acknowledgements.enableInstructionLine"
                        onChange={settingsFormik.handleChange}
                    />
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...wideTextFieldProps} label={"Instruction Line"} name="acknowledgements.instructionLine"
                        disabled={!getFormikField(settingsFormik, "acknowledgements.enableInstructionLine").value}
                        error={Boolean(getFormikField(settingsFormik, "acknowledgements.instructionLine").error)}
                        helperText={getFormikField(settingsFormik, "acknowledgements.instructionLine").error}
                        value={getFormikField(settingsFormik, "acknowledgements.instructionLine").value} onChange={settingsFormik.handleChange}
                    />
                </div>
                <div className="flex justify-center w-full">
                    <FormControlLabel
                        {...wideTextFieldProps}
                        className="mr-0"
                        control={
                            <Checkbox
                                value={Boolean(getFormikField(settingsFormik, "acknowledgements.enableFinishingLine").value)}
                            />
                        }
                        label={"Enable"} name="acknowledgements.enableFinishingLine"
                        onChange={settingsFormik.handleChange}
                    />
                </div>
                <div className="flex justify-center">
                    <TextField variant="filled" {...wideTextFieldProps} label={"Finishing Line"} name="acknowledgements.finishingLine"
                        disabled={!getFormikField(settingsFormik, "acknowledgements.enableFinishingLine").value}
                        error={Boolean(getFormikField(settingsFormik, "acknowledgements.finishingLine").error)}
                        helperText={getFormikField(settingsFormik, "acknowledgements.finishingLine").error}
                        value={getFormikField(settingsFormik, "acknowledgements.finishingLine").value} onChange={settingsFormik.handleChange}
                    />
                </div>
            </div>
        </>
    );
}
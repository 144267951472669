import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { setSearchBy } from '../../state/itemBrowseSlice';
import { useDispatch, useSelector } from '../../state';
import { ItemSearchInput } from 'orderflow-lambdas';
import { useTranslation } from 'react-i18next';

export default function BasicSelect() {
  const dispatch = useDispatch();
  const { t: tGC } = useTranslation([], { keyPrefix: "team.Global.Common" })
  const { t: tGT } = useTranslation([], { keyPrefix: "team.Global.Tables" })
  const { t: tCV } = useTranslation([], { keyPrefix: "team.Components.ItemBrowseTable" })

  const { searchBy } = useSelector((store) => store.itemBrowseSlice);


  const handleChange = (event: SelectChangeEvent) => {
    dispatch(setSearchBy(event.target.value as ItemSearchInput['searchBy']))
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{tGT("Search By")}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={searchBy}
          label={tGT("Search By")}
          onChange={handleChange}
        >
          <MenuItem value={'code'}>{tCV("Item Code")}</MenuItem>
          <MenuItem value={'vendorItemNumber'}>{tCV("Vendor Item Number")}</MenuItem>
          <MenuItem value={'customerPrice'}>{tCV("Customer Price")}</MenuItem>
          <MenuItem value={'commissionable'}>{tCV("Commissionable")}</MenuItem>
          <MenuItem value={'taxable'}>{tCV("Taxable")}</MenuItem>
          <MenuItem value={'description'}>{tCV("Description")}</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
import "immer"
import { createSlice } from "@reduxjs/toolkit"

import { signIn, AuthError, SignInOutput, SignInInput, getCurrentUser, AuthUser, signUp, SignUpOutput, ConfirmSignUpInput, ConfirmSignUpOutput, confirmSignUp, resendSignUpCode, ResendSignUpCodeInput, ResendSignUpCodeOutput, resetPassword, ResetPasswordInput, ResetPasswordOutput, confirmResetPassword, ConfirmResetPasswordInput,
fetchAuthSession, AuthSession, signOut,
} from "aws-amplify/auth";
import type { Dispatch, PayloadAction } from "@reduxjs/toolkit"
import { CredentialManager, client } from "../../util/api-client";
import { Team } from "orderflow-lambdas"
import { RootState } from "../store";
export interface UserState {
    user: "init" | "loading" | AuthUser | AuthError,
    session: AuthSession | undefined,
    team: "init" | "loading" | Team
    vanity?: string,
}

const initialState: UserState = {
    user: "init",
    session: undefined,
    team: "init",
}

export const userSlice = createSlice({
    name: "UserSlice",
    initialState,
    reducers: {
        setUser: (
            state,
            action: PayloadAction<"init" | "loading" | AuthUser | AuthError>,
        ) => ({
            ...state,
            user: action.payload,
        }),
        setVanity: (state, action: PayloadAction<string | undefined>) => ({
            ...state,
            vanity: action.payload,
        }),
        setSession: (state, action: PayloadAction<AuthSession | undefined>) => ({
            ...state,
            session: action.payload,
        }),
        setTeam: (state, action: PayloadAction<Team | "init" | "loading">) => ({
            ...state,
            team: action.payload,
        }),
    },
})

export const { setUser, setVanity, setSession, setTeam } = userSlice.actions
export default userSlice.reducer

interface LogInInput extends SignInInput {
    vanity?: string,
}

export const login = ({
    vanity,
    username,
    password,
}: LogInInput) => async (dispatch: Dispatch): Promise<SignInOutput> => {
    const loginResult = await signIn({
        username,
        password,
    });
    dispatch(setVanity(vanity))

    try {
        dispatch(setUser("loading"))
        const user = await getCurrentUser()
        dispatch(setUser(user))
    } catch (error: unknown) {
        if(error instanceof AuthError) {
            dispatch(setUser(error))
        } else {
            throw error
        }
    }


    return loginResult
}
export const register = (username: string, password: string) => async (): Promise<SignUpOutput> => {
    
        const signUpResult = await signUp({
            username,
            password,
            options: {
                userAttributes: {
                  email: username
                },
              },
        });
        return signUpResult
}

export const confirmAccount = (input: ConfirmSignUpInput) => 
    async (): Promise<ConfirmSignUpOutput> => {
        const result = await confirmSignUp(input);
        return result
}

export const resendConfirmation = (input: ResendSignUpCodeInput) => 
    async (): Promise<ResendSignUpCodeOutput> => {
    
        const result = await resendSignUpCode(input);
        return result
}

export const forgotPasswordChallenge = (input: ResetPasswordInput) => 
    async (): Promise<ResetPasswordOutput> => {
        const result =  resetPassword(input);
        return result
}

export const resetPasswordThunk = (input: ConfirmResetPasswordInput) => 
    async (): Promise<void> => {
        await confirmResetPassword(input);
}

export const getUser = () => async (dispatch: Dispatch): Promise<AuthUser | undefined> => {

    try {
        dispatch(setUser("loading"))
        const user = await getCurrentUser()
        const session = await fetchAuthSession()

        dispatch(setUser(user))
        dispatch(setSession(session))
        CredentialManager.setCredentials({
            accessKeyId: session.credentials?.accessKeyId,
            secretAccessKey: session.credentials?.secretAccessKey,
            sessionToken: session.credentials?.sessionToken,
        },
        user.userId
    )
        return user
        
    } catch (error: unknown) {
        if(error instanceof AuthError) {
            dispatch(setUser(error))
        } else {
            throw error
        }
    }

}

export const logOut = () => async (dispatch: Dispatch): Promise<void> => {
    try {
        await signOut()
        dispatch(setUser("init"))
        dispatch(setSession(undefined))
        dispatch(setTeam("init"))
        CredentialManager.setCredentials(undefined, undefined)
        CredentialManager.setTeamId(undefined)
        
    } catch (error: unknown) {
        if(error instanceof AuthError) {
            dispatch(setUser(error))
        } else {
            throw error
        }
    }

}

export const fetchTeam = () => async (dispatch: Dispatch, getState: () => RootState): Promise<Team | undefined> => {
        const {userSlice: { team }} = getState()
        if(team === "loading") return
        await dispatch(setTeam("loading"))
        const userTeam = await client.getMyTeam.query()
        CredentialManager.setTeamId(userTeam.TeamId)
        await dispatch(setTeam(userTeam))
        return userTeam
}
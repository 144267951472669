import { Button, TextField, Paper, InputAdornment, CircularProgress, Typography } from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';
import MailLockIcon from '@mui/icons-material/MailLock'; 
import LockIcon from '@mui/icons-material/Lock';
import styles from "./index.module.css";
import { useEffect, useState, Suspense } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, resetPasswordThunk } from "../../../state";
import { AuthError, ConfirmResetPasswordInput } from "aws-amplify/auth";
import PasswordReset from "./PasswordReset";

export default function Page(): JSX.Element {
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [genericError, setGenericError] = useState("");
  const [emailErrorMessage] = useState("");
  const [codeErrorMessage, setCodeErrorMessage] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [accountConfirmed, setAccountConfirmed] = useState(false);
  const searchParams = useSearchParams();
  const dispatch = useDispatch();

  const isValid = email && code && password;

  useEffect(() => {
    const _email = searchParams[0].get("email")
    if (_email) {
      const decodedEmail = decodeURIComponent(_email)
      setEmail(decodedEmail)
    }
  }, [searchParams[0].get("email")])

  const handleConfirm = async () => {
    try {
      setLoading(true)
      setCodeErrorMessage("")
      setGenericError("")
      setPasswordErrorMessage("")
      const input: ConfirmResetPasswordInput = {
        username: email,
        confirmationCode: code,
        newPassword: password
      }
      await dispatch(resetPasswordThunk(input));
      setAccountConfirmed(true)
    } catch (error: unknown | AuthError) {
      if (error instanceof AuthError) {
        if (error.name === "CodeMismatchException") {
          setCodeErrorMessage("Invalid Confirmation Code.")
        }
        if (error.name === "InvalidPasswordException") {
          setPasswordErrorMessage(error.message)
        }
        if (error.name === "LimitExceededException") {
          setGenericError(error.message)
        }
      }
      console.error(error)
    } finally {
      setLoading(false)
    }
  };

  if (accountConfirmed) return <PasswordReset />

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <section className={styles.main}>
        <Paper className={styles.wrapper} elevation={3}>
          <img
            src={window.location.origin + "/logo-black.png"}
            alt="logo"
            className={styles.logo}
            //width={logo.width}
            //height={logo.height}
          />
          <Typography align="center">
            Reset your password
          </Typography>
          {
            genericError && <Typography color="error" align="center">
              {genericError}
            </Typography>
          }
          <TextField
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            helperText={emailErrorMessage}
            error={Boolean(emailErrorMessage)}
            variant="standard"
            InputProps={{
              startAdornment: <InputAdornment position="start"><EmailIcon color="primary" /></InputAdornment>
            }}
          />
          <TextField
            label="Verification Code"
            value={code}
            onChange={(e) => {
              setCode(e.target.value)
            }}
            className={styles.verificationInput}
            helperText={codeErrorMessage}
            error={Boolean(codeErrorMessage)}
            fullWidth
            variant="outlined"
            InputProps={{
              startAdornment: <InputAdornment position="start"><MailLockIcon color="primary" /></InputAdornment>
            }}
          />
          <TextField
            label="New Password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value)
            }}
            error={Boolean(passwordErrorMessage)}
            helperText={passwordErrorMessage}
            className={styles.verificationInput}
            fullWidth
            variant="outlined"
            InputProps={{
              startAdornment: <InputAdornment position="start"><LockIcon color="primary" /></InputAdornment>
            }}
          />
          <Button
            className={styles.confirmButton}
            disabled={loading || !isValid}
            fullWidth
            variant="contained"
            onClick={handleConfirm}>
            {loading ? <CircularProgress color="inherit" size={20} /> : "Reset"}
          </Button>
        </Paper>
      </section>
    </Suspense>
  );
}

import * as Yup from 'yup';

export const detailsSchema = Yup.object().shape({
  companyName: Yup.string()
    .min(2, 'Too Short!')
    .max(255, 'Too Long!')
    .required('Required'),
  address: Yup.string()
    .max(255, 'Too Long!'),
  city: Yup.string()
    .max(255, 'Too Long!'),
  state: Yup.string()
    .max(255, 'Too Long!'),
  zipCode: Yup.string()
    .max(255, 'Too Long!'),
  country: Yup.string()
    .max(255, 'Too Long!'),
  website: Yup.string()
    .max(255, 'Too Long!'),
  position: Yup.string()
    .max(255, 'Too Long!'),
  email: Yup.string()
    .email('Invalid email'),
  phone: Yup.string()
    .max(255, 'Too Long!'),
  other: Yup.string()
    .max(255, 'Too Long!'),
  preferredCommunication: Yup.string()
    .max(255, 'Too Long!'),
})

export const billingAndDeliverySchema = Yup.object().shape({
  companyName: Yup.string()
    .max(255, 'Too Long!'),
  contactName: Yup.string()
    .max(255, 'Too Long!'),
  address: Yup.string()
    .max(255, 'Too Long!'),
  city: Yup.string()
    .max(255, 'Too Long!'),
  state: Yup.string()
    .max(255, 'Too Long!'),
  zipCode: Yup.string()
    .max(255, 'Too Long!'),
  country: Yup.string()
    .max(255, 'Too Long!'),
  position: Yup.string()
    .max(255, 'Too Long!'),
  email: Yup.string()
    .email('Invalid email'),
  phone: Yup.string()
    .max(255, 'Too Long!'),
  preferredCommunication: Yup.string()
    .max(255, 'Too Long!'),
})

export const paymentSchema = Yup.object().shape({
  currentBalance: Yup.string()
    .max(255, 'Too Long!'),
})

export const customerSchema = Yup.object().shape({
  details: detailsSchema,
  billing: billingAndDeliverySchema,
  delivery: billingAndDeliverySchema,
  payment: paymentSchema,
  notes: Yup.string()
    .max(1500, 'Too Long!'),
});

export const customerContactSchema = Yup.object().shape({
  contactName: Yup.string()
    .max(255, 'Too Long!'),
  address: Yup.string()
    .max(255, 'Too Long!'),
  city: Yup.string()
    .max(255, 'Too Long!'),
  state: Yup.string()
    .max(255, 'Too Long!'),
  zipCode: Yup.string()
    .max(255, 'Too Long!'),
  country: Yup.string()
    .max(255, 'Too Long!'),
  position: Yup.string()
    .max(255, 'Too Long!'),
  email: Yup.string()
    .email('Invalid email'),
  phone: Yup.string()
    .max(255, 'Too Long!'),
  preferredCommunication: Yup.string()
    .max(255, 'Too Long!'),
});
import * as React from 'react';
import Box from '@mui/material/Box';
import {
  DataGridPro,
  GridColDef,
  GridToolbarContainer,
  GridToolbarExport,
  GridColumnMenuProps,
  GridColumnMenu,
  GridLoadingOverlayProps,
  LoadingOverlayPropsOverrides,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro';
import { Button, LinearProgress } from '@mui/material';
import HeaderElipse from './headerElipse';
import RowButton from './rowButton';
import DropDown from './dropDown';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { useDispatch, useSelector } from '../../state';
import { setSearchInput, searchCustomers, setActiveSelection } from '../../state/customerBrowseSlice';
import { CustomerSearchInput } from 'orderflow-lambdas';
import { openCustomerPopupModal } from '../../state/customerPopupModalSlice';
import { topNavBarHeight } from '../NavBars/constants';
import { teams } from '../../../../../packages/orderflow-lambdas/src/shared/services';

interface LoaderProps extends Omit<GridLoadingOverlayProps, "color">, LoadingOverlayPropsOverrides {
  color?: string;
}

const Loader = (props: LoaderProps) => {
  return <LinearProgress color={"primary"} />;
}

const columns: GridColDef[] = [
  {
    field: 'code',
    headerName: "Customer Code",
    type: 'string',
    width: 120,
    renderCell: (params: GridRenderCellParams<any>) => (
      <span>
        {params.value}
      </span>
    )
  },
  {
    field: 'companyName',
    headerName: "CompanyName",
    width: 210,
    renderCell: (params: GridRenderCellParams<any>) => (
      <span>
        {params.value}
      </span>
    )
  },
  {
    field: 'contact',
    headerName: 'Contact',
    width: 180,
    renderCell: (params: GridRenderCellParams<any>) => (
      <span>
        {params.value}
      </span>
    )
  },
  {
    field: 'city',
    headerName: 'City',
    type: 'string',
    width: 140,
    renderCell: (params: GridRenderCellParams<any>) => (
      <span>
        {params.value}
      </span>
    )
  },
  {
    field: 'state',
    headerName: 'State',
    sortable: false,
    width: 100,
    renderCell: (params: GridRenderCellParams<any>) => (
      <span>
        {params.value}
      </span>
    )
  },
  {
    field: 'zip',
    headerName: 'Zip',
    type: 'string',
    width: 110,
    renderCell: (params: GridRenderCellParams<any>) => (
      <span>
        {params.value}
      </span>
    )
  },
  {
    field: 'telephone',
    headerName: 'Tel. No.',
    type: 'string',
    width: 160,
    renderCell: (params: GridRenderCellParams<any>) => (
      <span>
        {params.value}
      </span>
    )
  },
  {
    field: 'email',
    headerName: 'Email',
    type: 'string',
    width: 160,
    renderCell: (params: GridRenderCellParams<any>) => (
      <span>
        {params.value}
      </span>
    )
  },
  {
    field: 'action',
    headerName: '',
    width: 55,
    resizable: false,
    sortable: false,
    renderCell: params => {
      return <RowButton customer={params.row.customer}></RowButton>
    },
  }
];

function CustomToolbar() {
  const dispatch = useDispatch()
  const { searchInput, searchBy } = useSelector((store) => store.customerBrowseSlice)
  const { team } = useSelector((store) => store.userSlice)
  
  if(team === "init" || team === "loading") return null

  return (
    <GridToolbarContainer>
      <DropDown />
      <TextField
        value={searchInput}
        onChange={(e) => dispatch(setSearchInput(e.currentTarget.value))}
        id="filled-basic"
        label="Search for customers..."
        variant="outlined"
        InputProps={{
          startAdornment: <InputAdornment position="start"><SearchOutlinedIcon /></InputAdornment>,
        }}
      />
      <Button
        variant='outlined'
        color='primary'
        onClick={() => {
          const payload: CustomerSearchInput = { query: searchInput, searchBy, size: 100, page: 0 }
          dispatch(searchCustomers(payload))
        }}
      >Search</Button>
      <Box sx={{ flexGrow: 1 }} />
      <button
        className='text-blue-600 border-0 bg-inherit hover:cursor-pointer' onClick={() => { dispatch(openCustomerPopupModal()) }}>
        + ADD</button>
      <GridToolbarExport
        slotProps={{
          tooltip: { title: 'Export data' },
        }}
      />
      <HeaderElipse />
    </GridToolbarContainer>
  );
}

function CustomColumnMenu(props: GridColumnMenuProps) {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        // Hide `columnMenuColumnsItem`
        columnMenuColumnsItem: null,
        columnMenuFilterItem: null
      }}
    />
  );
}

export default function DataTables() {
  const dispatch = useDispatch();
  const { results, isLoading } = useSelector((store) => store.customerBrowseSlice);
  const { team } = useSelector((store) => store.userSlice);
  
  const initialResults = async () => {
    if(team === "init" || team === "loading") return null
    const payload: CustomerSearchInput = { query: '', searchBy: 'companyName', size: 100, page: 0 }
    await dispatch(searchCustomers(payload))
  }

  React.useEffect(() => {
    initialResults()
  }, [])


  return (
    <div style={{ height: `calc(100vh - ${topNavBarHeight} - 250px)` }}>
      <DataGridPro
        sx={{
          border: 'none', padding: '1rem', '.MuiDataGrid-row:nth-of-type(even)': {
            backgroundColor: '#ECECEC;',
          }, '.MuiDataGrid-scrollbar--horizontal': { display: 'none' }, '.MuiDataGrid-columnHeaderTitle': { fontWeight: 'bold' }
        }}
        rows={results ? results.results.map((result) => {
          return {
            id: result.CustomerId,
            code: result.code,
            companyName: result.details.companyName || '',
            contact: result.billing?.contactName || '',
            city: result.details.city || '',
            state: result.details.state || '',
            zip: result.details.zipCode || '',
            telephone: result.details.phone || '',
            email: result.billing?.email || '',
            customer: result
          }
        }) : []}

        loading={isLoading}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
        }}
        pinnedColumns={{ right: ["action"] }}
        pageSizeOptions={[1]}
        checkboxSelection
        disableRowSelectionOnClick
        disableColumnSorting
        disableColumnMenu
        autoHeight={false}
        slots={{
          loadingOverlay: Loader,
          toolbar: CustomToolbar,
          columnMenu: CustomColumnMenu
        }}
      />
    </div>
  );
}